import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import UseStore from "./../../stores/ShowroomStore";
import CoverFlow from "./../../layouts/CoverFlow/CoverFlow";

import "swiper/css/swiper.css";

const useStyles = makeStyles((theme) => ({
  griLightMain: {
    // color: theme.palette.griLightMain
  },
  griGreenMain: {
    //color: theme.palette.griGreenMain,
  },
}));

function SubCategorySelector() {
  const classes = useStyles();
  const setSelectedSubCategory = UseStore(
    (state) => state.setSelectedSubCategory
  );
  const setSelectedTire = UseStore((state) => state.setSelectedTire);
  const setRoutingByObject = UseStore((state) => state.setRoutingByObject);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const { enqueueSnackbar } = window.snack;
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

  const switchToTirePicker = (subCategory) => {
    if (
      tyresAndCategories[selectedCategory].machineries[subCategory].isSelected
    ) {
      setSelectedSubCategory(subCategory);
      setSelectedTire(null);
      setRoutingByObject(true);
      setIsTireZoomed(false)
    } else{
      enqueueSnackbar(
        `Sorry! You are not authorized to explore the ${subCategory}.`,
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box display="flex" justifyContent="center">
          <Grid item className={"title-container"} xs={12} md={8}>
            <p className={clsx(classes.griGreenMain, "agri-title page-title")}>
              {selectedCategory ? tyresAndCategories[selectedCategory].name.toUpperCase() : ""} TIRES
            </p>
            <p className={clsx(classes.griLightMain, "paragraph")}>
              {selectedCategory
                ? tyresAndCategories[selectedCategory].description
                : ""}
            </p>
          </Grid>
        </Box>
      </div>
      <CoverFlow clickFunction={switchToTirePicker} itemType="subcat" />
    </>
  );
}

export default SubCategorySelector;
