import create from "zustand"

const UseStore = create(set => ({
  start: false,
  preLocation: -1,
  location: -1, // 0 - tire categories, 1 - tire subcategories, 2 - tire finder, 3 - tire inspector
  actions: {
    endShowroom() {
      set(_state => ({ start: false, location: -1, preLocation: -1 }))
    },
    startShowroom() {
      set(state => ({ start: true, location: 0, preLocation: state.location }))
    },
    moveToTireCategories() {
      set(state => ({ start: true, location: 1, preLocation: state.location }))
    },
    moveToTirePicker() {
      set(state => ({ start: true, location: 2, preLocation: state.location }))
    },
    moveToTireInspector() {
      set(state => ({ start: true, location: 3, preLocation: state.location }))
    },
    goBackOneStep() {
      set(state => ({
        start: true,
        routingByObject: true,
        selectedCategory: 0 < state.location - 1 ? state.selectedCategory : null,
        selectedSubCategory: 1 < state.location - 1 ? state.selectedSubCategory : null,
        selectedTire: 2 < state.location - 1 ? state.selectedTire : null
      }))
    }
  },
  tyresAndCategories: null,
  selectedCategory: null,
  selectedSubCategory: null,
  selectedTire: null,
  isLoading: true,
  selectedType: 0,
  isTireZoomed: false,
  setselectedType(selectedType) { set(_state => ({ selectedType: selectedType })) },
  setIsLoading(isLoading) { set(_state => ({ isLoading: isLoading })) },
  setTyresAndCategories(tyresAndCategories) { set(_state => ({ tyresAndCategories: tyresAndCategories })) },
  setSelectedCategory(categoryValue) { set(_state => ({ selectedCategory: categoryValue })) },
  setSelectedSubCategory(categoryValue) { set(_state => ({ selectedSubCategory: categoryValue })) },
  setSelectedTire(categoryValue) { set(_state => ({ selectedTire: categoryValue })) },
  routingByObject: false,
  setRoutingByObject(isRouted) { set(_state => ({ routingByObject: isRouted })) },
  setIsTireZoomed(isZoomed) { set(_state => ({ isTireZoomed: isZoomed }))}

}));

export default UseStore;