import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import CallToAction from "./../components/tire_inspector/CallToAction";
import { useHistory } from "react-router-dom";

import UseStore from "../stores/ShowroomStore";
import { Locations } from "../res/Constants";
import ArButton from "../components/tire_inspector/ArButton";
import InquireButton from "../components/tire_inspector/InquireButton";
import SpecsButton from "../components/tire_inspector/SpecsButton";
import ShareButton from "../components/tire_inspector/ShareButton";
import { Html, HTML } from "@react-three/drei/web/Html";
import MetricsButton from "../components/tire_inspector/MetricsButton";
import ShareButtonMobile from "../components/tire_inspector/mobile_ui/ShareButtonMobile";
import ButtonGroupMobile from '../components/tire_inspector/mobile_ui/ButtonGroupMobile';
import ZoomOutButtonMobile from "../components/tire_inspector/mobile_ui/ZoomOutButtonMobile";
import ZoomButton from "../components/tire_inspector/ZoomButton";
import ZoomInButtonMobile from "../components/tire_inspector/mobile_ui/ZoomInButtonMobile";

// import mystyle from "./cardstyle.module.css";

const useStyles = makeStyles((theme) => ({
  rootHidden: {
    opacity: 0,
  },
  root: {
    flexGrow: 1,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    height: "100%",
    zIndex: 1,
    position: "absolute",
    width: "100vw",
    perspective: "100px",
  },
  gridClass: {
    width: "100%",
  },
  breadCrumbInline: {
    display: "inline",
  },
  breadCrumbSeperator: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  disabledPointer: {
    pointerEvents: "none",
  },
  enabledPointer: {
    pointerEvents: "all",
  },
  griLightMain: {
    marginBottom: "8px",
    // color: 'rgba(35,57,123,255)'
  },
  griGreenMain: {
    // color: "rgba(1,105,55,255)",
    // color: theme.palette.griGreenMain,
  },
  imgStyle: {
    width: "250px",
    objectFit: "contain",
    alignSelf: "center",
    justifySelf: "start",
    [theme.breakpoints.up('sm')]: {
      width: "320px",
    },
    [theme.breakpoints.up('md')]: {
      width: "400px",
    },
    [theme.breakpoints.up('xl')]: {
      width: "512px",
      marginTop:'5vh'
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  titleMarginTop: { 
    marginTop : '19vh'
  },
  informationBoxContainerLeft: {
    position: "absolute",
    top: "60%",
    left: "12%",
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.up('lg')]: {
      left: "20%",
    },
    [theme.breakpoints.up('xl')]: {
      left: "26%",
    }
  },
  informationBoxContainerRight: {
    position: "absolute",
    position: "absolute",
    top: "60%",
    right: "-8%",
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.up('lg')]: {
      right: "6%",
    },
    [theme.breakpoints.up('xl')]: {
      right: "12%",
    }
  },
  buttonContainer:{
    position: "absolute",
    bottom : '8%',
    right: 0,
    left: 0,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    maxWidth:'100%'
  }
}));

const useStylesPerformanceBar = (metricValue) =>
  makeStyles((theme) => ({
    progressbarMetric: {
      width: "200px",
      borderRadius: "5px",
      padding: "0px",
      background: "#0000001a",
      "&>div": {
        backgroundColor: "#113b77",
        width: `${metricValue}%`,
        height: "8px",
        borderRadius: "5px",
      },
    },
    informationBox: {
      paddingBottom: "1rem",
      marginLeft: "8%",
      "&:hover": {
        background: "rgba(6, 104, 57, 0.10)",
      },
    },
    progressbarLabel: {
      fontSize: "16px",
      marginTop: "0",
      marginBottom: "4px",
      color: "#113b77",
      textShadow: "0px 0px 6px rgb(201 241 222 / 73%)",
    },
    progressbarLeft: {
      marginRight: "auto",
    },
  }));

function PerformanceBar({ metric }) {
  const classes = useStylesPerformanceBar(metric.value)();
  return (
    <Box
      className={classes.informationBox}
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <p className={clsx(classes.progressbarLabel, "paragraph")}>
        {metric.name}
      </p>
      <div
        className={clsx(classes.progressbarMetric, classes.progressbarLeft)}
        style={{ "&>div": { width: `20%` } }}
      >
        <div></div>
      </div>
    </Box>
  );
}

function TireInspector({handleClick}) {
  const history = useHistory();
  const classes = useStyles();
  const location = UseStore((state) => state.location);
  const preLocation = UseStore((state) => state.preLocation);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const selectedTire = UseStore((state) => state.selectedTire);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const isTireZoomed = UseStore((state) => state.isTireZoomed);
  let path = ("/home/" + selectedCategory + "/" + selectedSubCategory + "/" + selectedTire);
  
  const tyreTypes = [
    { type: "Radial", id: 1 },
    { type: "Bias", id: 2 },
    { type: "Solid", id: 3 },
    { type: "Pneumatic", id: 5 },
    { type: "Test", id: 6 },
    { type: "Ultimate Air", id: 8 },
  ];
  let tyreType;
  if (selectedTire && (location === 3)) {
    tyreType = tyreTypes.find(
      (type) =>
        type.id ==
        tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
          .tyres[selectedTire].tyre_type_id
    ).type;
  }

  let selectedTireMetrics = [];
  if (selectedTire && (location === 3))
    selectedTireMetrics =
      tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
        .tyres[selectedTire].metrics;
  var rootClass = classes.rootHidden;

  if (
    (preLocation === Locations.INIT && location === Locations.INIT) ||
    (preLocation === Locations.INIT && location === Locations.CATEGORIES)
  ) {
    rootClass = classes.rootHidden;
  } else {
    rootClass = clsx(classes.root, classes.disabledPointer);
  }

  let qrItem;
  let glbFile;
  let usdzFile;
  let tireImg;
  let tireId;
  if (location === 3) {
    const category = tyresAndCategories[selectedCategory];
    if (category) {
      const sub = category.machineries[selectedSubCategory];
      if (sub) {
        const tyre = sub.tyres[selectedTire];
        if (tyre) {
          qrItem = tyre.ogmo_id;
          glbFile = tyre.glbPath;
          usdzFile = tyre.usdzPath;
          tireImg = tyre.image;
          tireId = tyre.id;
        }
      }
    }
  }

  return (
    <Html position={[0, 7.9882, 10]} style={{height: '100vh',height: `calc(var(--vh, 1vh) * ${100})`}}>
      <div
        id="tire-inspector-parent"
        className={rootClass}
         style={{
         transform: `translateX(-50%)`,
        }}
      >
        <Grid
          container
          id="tire-inspector-content"
          className={clsx(classes.gridClass, classes.disabledPointer)}
        >
          <Box
            display="flex"
            flexDirection="column"
            className={classes.disabledPointer}
            style={{ width: "100%" }}
          >
            <Box
              display="flex"
              className={classes.enabledPointer}
              justifyContent="center"
            >
              <Grid item className={clsx("title-container", classes.titleMarginTop)} xs={12} style={{}}>
              { !isTireZoomed && 
                <>
                <p
                  className={clsx(classes.griGreenMain, "agri-title tire-title")}
                >
                  {selectedTire && selectedCategory && selectedSubCategory ? tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].name : ""}
                </p>
                <a
                  className={clsx(
                    classes.griLightMain,
                    "breadcrumb",
                    classes.breadCrumbInline
                  )}
                  href="#"
                  onClick={(e) => handleClick(e, "home")}
                >
                  {selectedCategory ? tyresAndCategories[selectedCategory].name.toUpperCase() : ""} TIRES
              </a>
                <p
                  className={clsx(
                    classes.griLightMain,
                    "paragraph",
                    classes.breadCrumbInline,
                    classes.breadCrumbSeperator
                  )}
                >
                  -
              </p>
                <a
                  className={clsx(
                    classes.griLightMain,
                    "breadcrumb",
                    classes.breadCrumbInline
                  )}
                  href="#"
                  onClick={(e) => handleClick(e, "category")}
                >
                  {selectedSubCategory ? tyresAndCategories[selectedCategory].machineries[selectedSubCategory].name : ""}
                </a>
                <p
                  className={clsx(
                    classes.griLightMain,
                    "paragraph",
                    classes.breadCrumbInline,
                    classes.breadCrumbSeperator
                  )}
                >
                  -
              </p>
                <a
                  className={clsx(
                    classes.griLightMain,
                    "breadcrumb",
                    classes.breadCrumbInline
                  )}
                  href="#"
                  onClick={(e) => handleClick(e, "subcategory")}
                >
                  {(selectedTire && tyreType) ? tyreType.toUpperCase() : ""}
                </a>
                </>}
              </Grid>
            </Box>
            <Box style={{
                width: "100%",
                display: "flex",
                justifyContent:"center",
              }}>
              {glbFile === "" && <img className={classes.imgStyle} src={`/${(tireImg).split(".")[0]+".png"}`} alt="tire img" />}
            </Box>
            <Box
              flexGrow={1}
              className={clsx(
                classes.disabledPointer,
                classes.informationBoxHolder,
              )}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {!isTireZoomed && tireId &&
              <Box
                className={clsx(
                  classes.informationBoxContainerLeft,
                  classes.enabledPointer,
                  classes.sectionDesktop
                )}
              >
                {selectedTireMetrics.map((metric, index) => {
                  return <PerformanceBar metric={metric} key={tireId+"-"+index}/>
                })}
              </Box>
              }
              {!isTireZoomed && <Box  className={clsx(
                  classes.informationBoxContainerRight,
                  classes.enabledPointer,
                  classes.sectionDesktop
                )}>
                <ShareButton />
                <SpecsButton />
                <InquireButton />
              </Box>}
              {/* <CallToAction qrItem={qrItem} /> */}
              
              { glbFile !== "" &&  <div className={classes.buttonContainer}>
                  <ArButton path= {path} qrItem={qrItem} glbFile={glbFile} usdzFile={usdzFile}/>
                  <ZoomButton isZoomed={isTireZoomed}/>
              </div> }
              <ButtonGroupMobile path= {path} qrItem={qrItem} glbFile={glbFile} usdzFile={usdzFile}/>
            </Box>
          </Box>
        </Grid>
        
      </div>
    </Html>
  );
}

export default TireInspector;
