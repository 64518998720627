import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Icon from "@mdi/react";
import { mdiPowerStandby } from '@mdi/js';
import { mdiChevronLeft } from '@mdi/js';

import {Locations} from './../../res/Constants'

import AuthStore from './../../stores/AuthStateStore';
import UseStore from './../../stores/ShowroomStore'
import Pool from '../../UserPool';

const useStyles = makeStyles((theme) => ({
  root: {
    color:'#ffffff',
    height: '64px',
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    height: '2rem',
    display: 'flex',
    borderRadius: '12px',
    border: '1px solid #fff',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    '& ::placeholder': {
      color: 'white',
      opacity: 1,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },
  inputRoot: {
    color: 'inherit',
    height: '2rem',
    '& :focus': {
      outline: 'none'
    },
    [theme.breakpoints.up('md')]: {
      width: '32ch',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft:'-2ch'
    },
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    border: 'none',
    background: 'inherit',
    color: 'white',
    height: '2rem',
    width: '32ch',
    [theme.breakpoints.down('xs')]: {
      width: '26ch',
      paddingLeft: `calc(1em + ${theme.spacing(6)}px)`,
    },
  },
  optionFont: {
    fontSize: '18px',
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  sectionDesktop: {
    display: 'none',
    justifyContent:'space-between',
    width: '42h',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: "center"
    },
  },
  sectionMobile: {
    display: 'flex',
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  header: {
    zIndex:2,
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.2))',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  headerImage: {
    height: '60px',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-28px',
      height: '50px'
    },
  },
  buttonStyle: {
    padding: '5px',
    [theme.breakpoints.up('md')]: {
      padding: 'initial',
    },
  },
  firstDiv: {
    flexGrow:1,
    flexBasis:0,
    justifyContent: 'flex-start',
    alignItems: "center",
  },
  secondDiv: {
    flexGrow:1,
    flexBasis:0,
    justifyContent: 'center',
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  thirdDiv: {
    display:'flex',
    flexGrow:1,
    flexBasis:0,
    justifyContent: 'flex-end',
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));

export default function PrimarySearchAppBar() {
  const history = useHistory();
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isAuthenticated = AuthStore(state => state.isAuthenticated);
  const setAuthenticatedState = AuthStore(state => state.setAuthenticatedState);
  const setUserName = AuthStore((state) => state.setUserName);
  const setPhoneNumber = AuthStore((state) => state.setPhoneNumber);
  const setUserEmail = AuthStore((state) => state.setUserEmail);
  const setIdToken = AuthStore((state) => state.setIdToken);

  const location = UseStore(state => state.location);

  const goBackOneStep = UseStore(state => state.actions.goBackOneStep);
  const endShowroom = UseStore(state => state.actions.endShowroom);
  const setSelectedCategory = UseStore(state => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(state => state.setSelectedSubCategory);
  const setTyresAndCategories = UseStore(state => state.setTyresAndCategories);
  const setSelectedTire = UseStore(state => state.setSelectedTire);
  const setRoutingByObject = UseStore(state => state.setRoutingByObject);
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

  const logUserOut = (_event) => {
    const cognitoUser = Pool.getCurrentUser();
    if (cognitoUser !== null) {
     cognitoUser.signOut();
    }
    endShowroom()
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedTire(null);
    setAuthenticatedState(false);
    setRoutingByObject(true);
    setIsTireZoomed(false);
    setTyresAndCategories(null)
    clearAuthData()
  };

  const clearAuthData = () => {
    setUserEmail('')
    setUserName('')
    setPhoneNumber('')
    setIdToken('')
  }

  const goHome= () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedTire(null);
    setIsTireZoomed(false);
    history.push("/home");
  }

  const goBack= (_event) => {
    goBackOneStep();
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isAuthenticated &&
        <MenuItem onClick={logUserOut}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            color="inherit"
          >
            <Icon path={mdiPowerStandby}
              title="User Profile"
              size={1}
            />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      }  
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className={classes.root} color="transparent" position="absolute">
        <Toolbar className={classes.header}>
          <div className={classes.firstDiv}>
            <Button onClick={goHome}>
              <img className={classes.headerImage} src='/GRI-logo-white.svg'></img>
            </Button>
          </div>
          <div className={classes.sectionDesktop}>
            {(Locations.CATEGORIES < location) &&
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  color="inherit"
                  onClick={goBack}
                >
                  <Icon path={mdiChevronLeft}
                    title="Go Back"
                    size={1.3} />
                </IconButton>
              </div>
            }

            {isAuthenticated && <SearchBar />}

            {isAuthenticated &&
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  color="inherit"
                  onClick={logUserOut}
                >
                  <Icon path={mdiPowerStandby}
                    title="Log out"
                    size={1.3}
                  />
                </IconButton>
              </div>
            }
          </div>

          <div className={classes.secondDiv}>
            {isAuthenticated && <SearchBar />}
          </div>

          <div className={classes.thirdDiv}>
            {(Locations.CATEGORIES < location) &&
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  color="inherit"
                  onClick={goBack}
                  className={classes.buttonStyle}
                >
                  <Icon path={mdiChevronLeft}
                    title="Go Back"
                    size={1.3} />
                </IconButton>
              </div>
            }

            <div>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                className={classes.buttonStyle}
              >
                <MoreIcon />
              </IconButton>
            </div>

          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function SearchBar() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const tyresAndCategories = UseStore(state => state.tyresAndCategories);
  const setSelectedTire = UseStore(state => state.setSelectedTire);
  const setSelectedSubCategory = UseStore(state => state.setSelectedSubCategory);
  const setSelectedCategory = UseStore(state => state.setSelectedCategory);
  const setSelectedTireName = UseStore(state => state.setSelectedTireName);
  const setRoutingByObject = UseStore(state => state.setRoutingByObject);
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);
  const moveToTireInspector = UseStore(state => state.actions.moveToTirePicker);
  const [tyreLoaded, setTyreLoaded] = React.useState(false);

  const getUserAssignedTyres = () => {
    let assignedTyres = []
    if (tyresAndCategories) {
      Object.keys(tyresAndCategories).forEach(categoryKey => {
        if (tyresAndCategories[categoryKey].isSelected) {
          Object.keys(tyresAndCategories[categoryKey].machineries).forEach(subCategoryKey => {
            if (tyresAndCategories[categoryKey].machineries[subCategoryKey].isSelected) {
              Object.keys(tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres).forEach(tyreKey => {
                if (tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[tyreKey].isSelected) {
                  assignedTyres.push({ categoryKey: categoryKey, subCategoryKey: subCategoryKey, tyreKey: tyreKey })
                }
              });
            }
          });
        }
      });
    }
    return assignedTyres
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const assignedTyres=getUserAssignedTyres()
        setOptions(assignedTyres)
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleSearchOnClick = (event, values) => {
    if (values) {
      setTyreLoaded(true);
      setSelectedCategory(values.categoryKey);
      setSelectedSubCategory(values.subCategoryKey);
      setSelectedTire(values.tyreKey);
      setIsTireZoomed(false);
      setRoutingByObject(true);
      moveToTireInspector();
    }
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <Autocomplete
        id="header-search-bar"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        groupBy={(option) => tyresAndCategories[option.categoryKey].name}
        getOptionSelected={(option, value) => option.tyreKey === value.tyreKey}
        getOptionLabel={(option) => tyresAndCategories[option.categoryKey].machineries[option.subCategoryKey].tyres[option.tyreKey].name}
        options={options}
        loading={loading}
        onChange={(e, value) => handleSearchOnClick(e, value)}
        classes={{
          input: classes.inputInput,
        }}
        renderOption={(option)=>
          <Typography className ={classes.optionFont}>{tyresAndCategories[option.categoryKey].machineries[option.subCategoryKey].tyres[option.tyreKey].name}</Typography>
        }
        renderInput={(params) => (
          <div className ={classes.inputRoot} ref={params.InputProps.ref}>
            <input type="text" variant="outlined" {...params.inputProps} placeholder="Search Tire" />
          </div>
        )}
      />
    </div>
  )
}