import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SubCategorySelector from './../components/tire_and_subcategory_picker/SubCategorySelector';
import TireSelector from './../components/tire_and_subcategory_picker/TireSelector';

import UseStore from './../stores/ShowroomStore';
import { Locations } from './../res/Constants';
import { Html } from '@react-three/drei';

const useStyles = makeStyles((theme) => ({
  rootHidden: {
    opacity: 0,
    pointerEvents: 'none'
  },
  root: {
    pointerEvents: 'all',
    flexGrow: 1,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    zIndex: 1,
    position: 'absolute',
    width: '100vw',
    perspective: '100px',
  },
  gridClass: {
    width: '100%',
  }
}));

function TireAndSubCategoryPicker({handleClickTire}) {
  const classes = useStyles();
  var rootClass = classes.rootHidden;

  const location = UseStore(state => state.location);
  const preLocation = UseStore(state => state.preLocation);

  if ((preLocation === Locations.INIT && location === Locations.INIT) || (preLocation === Locations.INIT && location === Locations.CATEGORIES)) {
    rootClass = classes.rootHidden;
  } else {
    rootClass = classes.root;
  }

  return (
    <Html position={[10, 2.80, 0]}>
      <div>
        <div className={rootClass} style={{width: '100vw', transform:' translate(-50%, -50%)'}}>
          <Grid container className={classes.gridClass} >
            {(location <= Locations.SUBCATEGORIES) && <SubCategorySelector />}
            {(Locations.TIRE_PICKER <= location) && <TireSelector handleClickTire={handleClickTire}/>}
          </Grid>
        </div>
      </div>
    </Html>
  )
}

export default TireAndSubCategoryPicker;