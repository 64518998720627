import React from 'react';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import Styleclass from './specsstyle.module.css';
import UseStore from "./../../stores/ShowroomStore";
import Tooltip from '@material-ui/core/Tooltip';

function SpecsButton() {
    const location = UseStore((state) => state.location);
    const selectedCategory = UseStore((state) => state.selectedCategory);
    const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
    const selectedTire = UseStore((state) => state.selectedTire);
    const tyresAndCategories = UseStore((state) => state.tyresAndCategories);

    let pdfLink= null;
    if (location === 3) {
        const category = tyresAndCategories[selectedCategory];
        if (category) {
          const sub = category.machineries[selectedSubCategory];
          if (sub) {
            const tyre = sub.tyres[selectedTire];
            if (tyre) {
                pdfLink = tyre.international_standard_pdf;
            }
        }
    }
}

    const handleDownloadSpec = () => {
        return window.open(
            `https://gritires.com/storage/${tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].international_standard_pdf}`,
            "_blank"
        );
    };

    const specTipOpen = true;

    return (
        <>
        {
            pdfLink !== null && <div className={Styleclass.container}>
                <div className={Styleclass.labelContainer}>
                    <p className={Styleclass.textStyle}>Download Specs</p>
                </div>
                <button className={Styleclass.spbutton} onClick={handleDownloadSpec}>
                    <SystemUpdateAltOutlinedIcon style={{ color: "#fff" }} />
                </button>
            </div>
        }
        </>
    );
}

export default SpecsButton;