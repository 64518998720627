import fetchClient from "./fetchClient";

function getUser(userName, idToken) {
  const requestBody = {
    user_name: userName,
  };
  return fetchClient("users/getUser", { body: requestBody }, idToken, true);
}

function getTyres(token) {
  var idToken = token || null
  return fetchClient("tires/all", {}, idToken, true);
}

function sendInquiryEmail(phone, message, email, username, sku, tire_name, idToken) {
  const requestBody = {
    user_name: username,
    email_id: email,
    contact_no: phone,
    sku: sku,
    tire_name: tire_name,
    message: message,
  };
  return fetchClient("user/inquiry", { body: requestBody }, idToken, true);
}

function getShortLink(longUrl, idToken) {
  const requestBody = {
    long_url: longUrl
  };
  return fetchClient("shortlink", {body: requestBody}, idToken, true);
}

function notifyForgotPassword(email_id) {
  const requestBody = {
    email_id: email_id,
  };
  return fetchClient("user/notifyForgotPassword", { body: requestBody }, "", false);
}

export {
  getUser,
  getTyres,
  sendInquiryEmail,
  getShortLink,
  notifyForgotPassword
};
