import React, { useRef } from "react";
import Swiper from "react-id-swiper";
import SlideItem from "./slideItem";
import {
  Box,
  makeStyles
} from '@material-ui/core';

let userAgentString = navigator.userAgent;
let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

const useStyles = makeStyles((theme) => ({

  sliderParent: {
    '&>.swiper-container': {
      width: '100%',
      marginTop: '-20px',
      "&>.swiper-wrapper": {
        "&>.swiper-slide": {
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '13rem',
          opacity: 1,
          [theme.breakpoints.up('sm')]: {
            width: '15rem',
          },
          [theme.breakpoints.up('md')]: {
            width: '19rem',
          },
          // [theme.breakpoints.up('lg')]: {
          //   width: '23rem',
          //   height: '23rem',
          // },
        },
        "&>.swiper-slide-prev, &>.swiper-slide-next": {
          opacity: 1,
        },
        "&>.swiper-slide-active": {
          opacity: 1,
          // border: '2px solid #00ff4c',
          // boxShadow: '0 0 5px #00ff4c, 0 0 10px #27ae6d, 0 0 15px #000000',
        },
      }
    },
  },
  selectedBullet: {
    opacity: '1!important',
    background: `${'rgba(35,57,123,255)'}!important`
    // background: `${theme.palette.griGreenSecondary}!important`
  },
  bullet: {
    width: '8px',
    height: '8px',
    display: 'inline-block',
    borderRadius: '100%',
    opacity: 0.6,
    margin: '0px 4px',
    background: 'rgba(35,57,123,255)',
    // background: theme.palette.griGreenMain
  }
}));

const SlideContainer = props => {
  const classes = useStyles();
  return (
    <Box className={classes.sliderParent} {...props} />
  )
}

const Slider = ({ items, clickFunction, activeSlideKey }) => {
  const classes = useStyles();
  // Swiper instance
  const swiperRef = useRef(null);

  // Params definition
  const initialSlide = Math.floor(((Object.keys(items).length) - 1) / 2);

  const params = {
    effect: "coverflow",
    loop: false,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    spaceBetween: firefoxAgent ? 0 : -50,
    initialSlide,
    autoHeight: true,
    rebuildOnUpdate: false,
    shouldSwiperUpdate: true,
    autoWidth: false,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: firefoxAgent ? 0 : 200,
      modifier: 1,
      slideShadows: false,
    },
    pagination: {
      el: ".swiper-pagination",
      bulletActiveClass: classes.selectedBullet,
      bulletClass: classes.bullet,
    },
    on: {
      'init': () => {
        const swiperInstance = swiperRef.current.swiper;
        
        if(swiperInstance) {
          swiperInstance.update()
          setTimeout(() => {swiperInstance.slideTo(initialSlide)},50);
        }
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <SlideContainer>
        {items && (
          <Swiper {...params} ref={swiperRef}>
          {items.map(item=> 
            <SlideItem color={"#494949"} item={item} id={item.key} key={`slide_${item.key}`} clickFunction={clickFunction} />
          )}
        </Swiper>
        )}
        
      </SlideContainer>
    </Box>
  );
};

export default Slider