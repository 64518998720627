import React, { useEffect } from "react";
import MainScreen from "./screens/MainScreen";
import PrimarySearchAppBar from "./layouts/MainLayout/Header";
import "./BaseStyle.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Router from "./Router";
import Pool from "../src/UserPool";
import AuthStore from "./stores/AuthStateStore";
import UseStore from "./stores/ShowroomStore";
import LoadSpinner from "./LoadingComponent/LoadSpinner";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { useSnackbar } from "notistack";

function App() {
  const setAuthenticatedState = AuthStore(
    (state) => state.setAuthenticatedState
  );
  const startShowroom = UseStore((state) => state.actions.startShowroom);
  const setRoutingByObject = UseStore((state) => state.setRoutingByObject);
  const isLoading = UseStore((state) => state.isLoading);
  const setIsLoading = UseStore((state) => state.setIsLoading);
  const setUserEmail = AuthStore((state) => state.setUserEmail);
  const setIdToken = AuthStore((state) => state.setIdToken);
  const email = AuthStore((state) => state.email);

  window.snack = useSnackbar()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const userName = query.get('__user_name')
    const idToken = query.get('__id_token')
    if (idToken && userName){
      startSession();
    }else{
      getSession();
    }
  }, []);

  useEffect(() => {
    window.onUsersnapCXLoad = function(api) {
      api.init({
        user: {
          email: email,
        }
      });
    }
  },[email])
  
  const startSession = () =>{
    const query = new URLSearchParams(window.location.search);
    const userName = query.get('__user_name')
    const idToken = query.get('__id_token')
    setUserEmail(userName)
    setIdToken(idToken)
    setAuthenticatedState(true);
  }

  const getSession = async () => {
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((error, session) => {
          if (error) {
            reject();
          } else {
            if (session.isValid()) {
              setAuthenticatedState(true);
              startShowroom();
            } else {
              setAuthenticatedState(false);
            }
          }
        });
      } else {
        setAuthenticatedState(false);
        resolve();
      }
      setIsLoading(false);
    });
  };
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <div id="app-container" className="App">
          <Switch>
            <Route path="/">
                {!isLoading ? <Router /> : <LoadSpinner />}
            </Route>
          </Switch>
          <LoadSpinner />
          <PrimarySearchAppBar />
            <MainScreen />
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
