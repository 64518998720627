import React from 'react';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import Styleclass from './inquirestyle.module.css';
import InquiryPopup from './InquiryPopup';
import Tooltip from '@material-ui/core/Tooltip';

function InquireButton() {
    const [inquiryOpen, setinquiryOpen] = React.useState(false);

    const handleInquiry = () => {
        setinquiryOpen(true);
    };

    return (
        <>
            <div className={Styleclass.container}>
                <div className={Styleclass.labelContainer}>
                    <p className={Styleclass.textStyle}>Inquire</p>
                </div>
                <button className={Styleclass.iqbutton} onClick={handleInquiry}>
                    <EmailOutlinedIcon style={{ color: "#fff" }} />
                </button>
            </div>
            {
                inquiryOpen === true && <InquiryPopup iqopen={inquiryOpen} setiqopen={setinquiryOpen} />
            }
        </>
    );
}

export default InquireButton;