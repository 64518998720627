import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import UseStore from "./../../stores/ShowroomStore";
import CoverFlow from "./../../layouts/CoverFlow/CoverFlow";

import "swiper/css/swiper.css";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  breadCrumbInline: {
    display: "inline",
  },
  breadCrumbSeperator: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonContainer: {
    marginTop: "10px",
    marginBottom: "15px",
    borderRadius: "12px",
    padding: "3px",
    [theme.breakpoints.up('sm')]: {
      marginTop: "15px",
      marginBottom: "20px",
    },
    // background: theme.palette.transparency.dark2,
  },
  button: {
    borderWidth: "0px!important",
    background: "transparent",
    borderRadius: "10px!important",
    fontFamily: "Open Sans, sans-serif",
    fontWeight:"600",
    color: "#113b77",
    "&:hover": {
      background: "#113b77",
      color:"white",
      // background: theme.palette.griGreenMain,
      // color: theme.palette.griLightMain,
    },
  },
  buttonSelected: {
    background: "#113b77",
    color:"white",
    // background: theme.palette.griGreenMain,
    // color: theme.palette.griLightMain,
  },
  griLightMain: {
    // color: "rgba(35,57,123,255)",
    // color: theme.palette.griLightMain
  },
  griGreenMain: {
    // color: "rgba(1,105,55,255)",
    // color: theme.palette.griGreenMain,
  },
}));

function TireSelector({handleClickTire}) {
  const { enqueueSnackbar } = window.snack;
  const history = useHistory();
  const classes = useStyles();
  const setRoutingByObject = UseStore((state) => state.setRoutingByObject);
  const setSelectedTire = UseStore((state) => state.setSelectedTire);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const setSelectedCategory = UseStore((state) => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(
    (state) => state.setSelectedSubCategory
  );
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const setselectedType = UseStore((state) => state.setselectedType);
  const selectedType = UseStore((state) => state.selectedType);
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

  const inspectTyre = (tyreId) => {
    if (
      tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
        .tyres[tyreId].isSelected
    ) {
      setSelectedTire(tyreId);
      setIsTireZoomed(false)
      setRoutingByObject(true);
    } else {
      enqueueSnackbar(
        "Sorry! You are not authorized to explore the Tire",
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
    }
  };

  const tyreType = () => {
    let tyreTypeMapped = [{ type: "All", id: 0 }];
    if (selectedSubCategory) {
      const types = [
        { type: "All", id: 0 },
        { type: "Radial", id: 1 },
        { type: "Bias", id: 2 },
        { type: "Solid", id: 3 },
        { type: "Pneumatic", id: 5 },
        { type: "Test", id: 6 },
        { type: "Ultimate Air", id: 8 },
      ];
      types.forEach((type) => {
        let filteredTyres = Object.keys(
          tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
            .tyres
        ).filter(
          (tyreKey) =>
            tyresAndCategories[selectedCategory].machineries[
              selectedSubCategory
            ].tyres[tyreKey].tyre_type_id == type.id
        );
        if (filteredTyres.length)
          tyreTypeMapped.push({ type: type.type, id: type.id });
      });
    }
    return tyreTypeMapped;
  };
  const tyreTypeMapped = tyreType();

  const handleTyreType = (id) => {
    setselectedType(id);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box display="flex" justifyContent="center">
          <Grid item className={"title-container"} xs={12}>
            <p className={clsx(classes.griGreenMain, "agri-title page-title")}>
              {selectedSubCategory ? tyresAndCategories[selectedCategory].machineries[selectedSubCategory].name : ""}
            </p>
            <a
              className={clsx(
                classes.griLightMain,
                "breadcrumb",
                classes.breadCrumbInline
              )}
              href="#"
              onClick={(e) => handleClickTire(e, "category")}
            >
              {selectedCategory ? tyresAndCategories[selectedCategory].name.toUpperCase() : ""} TIRES
            </a>
            <p></p>
            <ButtonGroup
              className={classes.buttonContainer}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              {tyreTypeMapped.map((item) => (
                <Button
                  key = {item.id}
                  className={clsx(
                    selectedType == item.id && classes.buttonSelected,
                    classes.button
                  )}
                  onClick={() => handleTyreType(item.id)}
                >
                  {item.type}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Box>
      </div>
      <CoverFlow
        clickFunction={inspectTyre}
        itemType="tyre"
        selectedType={selectedType}
      />
    </>
  );
}

export default TireSelector;
