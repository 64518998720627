import React from "react";
import Button from "@material-ui/core/Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <center>
          <h1>Something went wrong.</h1>
          <Button variant="contained">
            <a href="/home" style={{ textDecoration: "None" }}>
              Return to Home Page
            </a>
          </Button>
        </center>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
