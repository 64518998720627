import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import UseStore from "../../stores/ShowroomStore";
import SpecsButtonMobile from './mobile_ui/SpecsButtonMobile';

const useStyles = makeStyles((theme) => ({
  closebtn: {
    color: '#113b77',
  },
}));

const useStylesPerformanceBar = (metricValue) =>
  makeStyles((theme) => ({
    progressbarMetric: {
      width: "200px",
      borderRadius: "5px",
      padding: "0px",
      background: "#0000001a",
      "&>div": {
        backgroundColor: "#113b77",
        // backgroundColor: theme.palette.transparency.green85,
        width: `${metricValue}%`,
        height: "8px",
        borderRadius: "5px",
      },
    },
    informationBox: {
      paddingBottom: "1rem",
      "&:hover": {
        background: "rgba(6, 104, 57, 0.10)",
      },
    },
    progressbarLabel: {
      fontSize: "16px",
      marginTop: "0",
      marginBottom: "4px",
      color: "#113b77",
      // color: theme.palette.griLightMain,
      textShadow: "0px 0px 6px rgb(201 241 222 / 73%)",
    },
    progressbarLeft: {
      marginRight: "auto",
    },
  }));

function PerformanceBar({ metric }) {
  const classes = useStylesPerformanceBar(metric.value)();
  return (
    <Box
      className={classes.informationBox}
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <p className={clsx(classes.progressbarLabel, "paragraph")}>
        {metric.name}
      </p>
      <div
        className={clsx(classes.progressbarMetric, classes.progressbarLeft)}
        style={{ "&>div": { width: `20%` } }}
      >
        <div></div>
      </div>
    </Box>
  );
}

function MetricsPopup({mtrOpen, setMtrOpen }) {

  const classes = useStyles();
  const location = UseStore((state) => state.location);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const selectedTire = UseStore((state) => state.selectedTire);

  let selectedTireMetrics = [];
  if (selectedTire && (location === 3))
    selectedTireMetrics =
      tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
        .tyres[selectedTire].metrics;

  const handleClose = () => {
    setMtrOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          open={mtrOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ display: 'flex', justifyContent: 'center',padding: '30px 30px 0px' }}>
            <div style={{ display: 'flex',flexDirection: 'column', justifyContent: 'center'}}>
            {selectedTireMetrics.map((metric) => {
                  return <PerformanceBar metric={metric}/>;
                })}
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-evenly',alignItems: 'center',margin: '20px 0px',padding: '0px 10px'}}>
            <SpecsButtonMobile />
          </div>
          <DialogActions>
            <Button onClick={handleClose} className={classes.closebtn}>
              Close
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default MetricsPopup;