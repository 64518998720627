import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { sendInquiryEmail } from "../../server/UserAPI";
import Pool from "../../UserPool";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthStore from "./../../stores/AuthStateStore";
import UseStore from "./../../stores/ShowroomStore";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import Styleclass from './inquiryPopup.module.css';
import {useFormik} from 'formik';
import * as Yup from 'yup';
 
const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'unset',
  },
  buttonProgress: {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function InquiryPopup({ iqopen, setiqopen }) {
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const selectedTire = UseStore((state) => state.selectedTire);
  const userName = AuthStore((state) => state.userName);
  const email = AuthStore((state) => state.email);
  const phoneNo = AuthStore((state) => state.phoneNumber);
  const idToken = AuthStore((state) => state.idToken);
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = window.snack

  const sku =
  tyresAndCategories[selectedCategory].name + " - " + tyresAndCategories[selectedCategory].machineries[selectedSubCategory].name + " - " + tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].name;

  const handleClose = () => {
    setiqopen(false);
  };

  // const updateName = () => {
  //   user.getSession((err) => {
  //     if (err) {
  //       console.log(err);
  //     } else {
  //       const attributes = [
  //         new CognitoUserAttribute({ Name: "name", Value: inputName }),
  //       ];
  //       user.updateAttributes(attributes, (err, result) => {
  //         if (err) console.error(err);
  //         console.log(result);
  //       });
  //     }
  //   });
  // };

  const initialValues = {
    name: userName ? userName : "",
    email: email ? email : "",
    phoneNo: phoneNo ? phoneNo : "",
    message: '',
  }

  const onSubmit = values => {
    if (
      values.phoneNo.trim() !== "" &&
      values.name.trim() !== "" &&
      values.email.trim() !== ""
    ) {
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 5000);
      sendInquiryEmail(
        values.phoneNo,
        values.message,
        values.email,
        values.name,
        sku,
        tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].name,
        idToken
      )
        .then(() => {
          setiqopen(false);
          enqueueSnackbar("Inquiry Successful!", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          // if (!userName) {
          //   updateName();
          // }
        })
        .catch(() => {
          enqueueSnackbar("Inquiry Failed!", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        });
    } else {
      setIsSubmitting(false);
    }
  }

  const validationSchema =  Yup.object({
    name: Yup.string().required('The name field is required.'),
    email: Yup.string().email('Invalid email address').required('The email field is required.'),
    phoneNo: Yup.string().required('The Contact Number field is required.'),
    message: Yup.string().required('The message field is required.'),
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange:false,
    validateOnBlur:false
  })

  return (
    <Dialog
      open={iqopen}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="body"
      maxWidth="md"
      classes={{paper: classes.paper}}
    >
      <div onClick={handleClose} className={Styleclass.closeBtn}>
      </div>
      <div className={Styleclass.container}>
        <h1 className={Styleclass.title}>Product Inquiry</h1>
        <p className={Styleclass.description}>For all inquiries, Please contact us using the form below.</p>
        <p className={Styleclass.description}>{"Product SKU is " + sku}</p>
        <form onSubmit={formik.handleSubmit} className={Styleclass.formStyle}>
          <div className={Styleclass.inputDiv}>
            <svg className={formik.errors.name ? `${Styleclass.inputLogo} ${Styleclass.errorColor}` : Styleclass.inputLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
            </svg>
            <input className={formik.errors.name ? Styleclass.errorinput : Styleclass.textField} name="name" type="text" onChange={formik.handleChange} value={formik.values.name} />
            {formik.values.name === "" ? <label htmlFor="name" className={formik.errors.name ? `${Styleclass.labelStyle} ${Styleclass.errorColor}` : Styleclass.labelStyle}>Your Name</label> : null}
            {formik.errors.name ? <div className={Styleclass.errormsg}>{formik.errors.name}</div> : null}
          </div>
          <div className={Styleclass.inputDiv}>
            <svg className={formik.errors.email ? `${Styleclass.inputLogo} ${Styleclass.errorColor}` : Styleclass.inputLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <input className={formik.errors.email ? Styleclass.errorinput : Styleclass.textField} name="email" type="email" onChange={formik.handleChange} value={formik.values.email} />
            {formik.values.email === "" ? <label htmlFor="email" className={formik.errors.email ? `${Styleclass.labelStyle} ${Styleclass.errorColor}` : Styleclass.labelStyle}>Your email address</label> : null}
            {formik.errors.email ? <div className={Styleclass.errormsg}>{formik.errors.email}</div> : null}
          </div>
          <div className={Styleclass.inputDiv}>
            <svg className={formik.errors.phoneNo ? `${Styleclass.inputLogo} ${Styleclass.errorColor}` : Styleclass.inputLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <input className={formik.errors.phoneNo ? Styleclass.errorinput : Styleclass.textField} name="phoneNo" type="text" onChange={formik.handleChange} value={formik.values.phoneNo} />
            {formik.values.phoneNo === "" ? <label htmlFor="phoneNo" className={formik.errors.phoneNo ? `${Styleclass.labelStyle} ${Styleclass.errorColor}` : Styleclass.labelStyle}>Your contact number</label> : null}
            {formik.errors.phoneNo ? <div className={Styleclass.errormsg}>{formik.errors.phoneNo}</div> : null}
          </div>
          <div className={Styleclass.inputDiv}>
            <textarea cols="30" rows="7" className={formik.errors.message ? Styleclass.errorinput : Styleclass.textField} name="message" type="TextField" onChange={formik.handleChange} value={formik.values.message} />
            {formik.values.message === "" ? <label htmlFor="message" className={formik.errors.message ? `${Styleclass.labelStyle} ${Styleclass.errorColor}` : Styleclass.labelStyle}>Your Inquiry</label> : null}
            {formik.errors.message ? <div className={Styleclass.errormsg}>{formik.errors.message}</div> : null}
          </div>
          <button type="submit" className={Styleclass.submitBtn}>{isSubmitting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}Submit</button>
        </form>
      </div>
    </Dialog>
  );
}
