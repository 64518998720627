import React, { useState, useEffect, useRef } from 'react'
import * as THREE from 'three'

import { useGLTF } from '@react-three/drei'

import CameraController from '../components/CameraController'
import videoPath from './../res/landing-main.mp4'

import CategoryItem from '../components/category_picker/CategoryItem'
import TireInspectorItem from '../components/tire_inspector/TireInspectorItem'

import { useEquirectangularHDR, useRenderedSky } from '../components/main_screen/SkyBox'
import { CategoryId } from '../res/Constants'
import aoMapPath from './../res/aoMap.png'
import lightMapPath from './../res/lightMap.png'
import { Html, useTexture } from '@react-three/drei'
import UseStore from './../stores/ShowroomStore';
import { Suspense } from 'react';
import LoaderTire from '../LoadingComponent/LoaderTire';
import TireInspector from './TireInspector'
import TireAndSubCategoryPicker from './TireAndSubCategoryPicker'
import Hand from '../res/hand.png';
import Styleclass from './gifStyle.module.css';
import agricultureImage from './../res/categories/agriculture.png';
import constructionImage from './../res/categories/construction.png';
import materialImage from './../res/categories/material.png';

export default function ShowRoomModel(props) {
  const location = UseStore((state) => state.location);
  const selectedTire = UseStore((state) => state.selectedTire);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const setSelectedCategory = UseStore(state => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(state => state.setSelectedSubCategory);
  const setSelectedTire = UseStore(state => state.setSelectedTire);
  const setRoutingByObject = UseStore(state => state.setRoutingByObject);

  let glbFile = "";

  if (location === 3) {
    const category = tyresAndCategories[selectedCategory];
    if (category) {
      const sub = category.machineries[selectedSubCategory];
      if (sub) {
        const tyre = sub.tyres[selectedTire];
        if (tyre) {
          glbFile = tyre.glbPath;
        }
      }
    }
  }

  const { scene, nodes, animations } = useGLTF('/GLB/GRI_Scene.glb')
  const spotLight = useRef(null)
  const target = useRef();

  const [tv, setTv] = useState(nodes.TV);

  const [agriculture_RT] = useState(nodes.Agriculture_RT);
  const [Construction_RT] = useState(nodes.Construction_RT);
  const [Material_Handling_RT] = useState(nodes.Material_Handling_RT);
  const [GRILogoPlane] = useState(nodes.GRILogoPlane);
  const [LightShade] = useState(nodes.LightShade);
  const [Light_beams] = useState(nodes.Light_beams);
  const [Floor] = useState(nodes.Floor);
  const [light] = useState(nodes.lights);
  const [wall] = useState(nodes.wall);
  const [Tire] = useState(nodes.Tire);
  const [TireShdow] = useState(nodes.TireShdow);
  const isLoading = UseStore(state => state.isLoading);

  const aoMap = useTexture(aoMapPath);
  const lightMap = useTexture(lightMapPath);
  aoMap.flipY = false
  lightMap.flipY = false

  let skybox = useEquirectangularHDR(); //useRenderedSky();
  scene.background = skybox;

  scene.traverse(object => {
    if (object.isMesh) {
      object.material.envMap = skybox;
      object.material.aoMap = aoMap;
      object.material.lightMap = lightMap;
    }
  })

  Light_beams.material.aoMap = null;
  LightShade.material.aoMap = null;
  //Tire.material.aoMap = null;
  //TireShdow.material.aoMap = null;
  //Light_beams.material.lightMap = null;

  Construction_RT.material.transparent = true;
  agriculture_RT.material.transparent = true;
  Material_Handling_RT.material.transparent = true;
  LightShade.material.transparent = true;
  //TireShdow.material.transparent = true;


  scene.remove(agriculture_RT);
  scene.remove(Construction_RT);
  scene.remove(Material_Handling_RT);
  scene.remove(Tire);
  scene.remove(GRILogoPlane)

  //const [x, y] = useAspect("cover", 1280, 720)
  const [video] = useState(() => {
    const vid = document.createElement("video")
    vid.src = videoPath
    vid.crossOrigin = "Anonymous"
    vid.loop = true
    vid.muted = true;
    vid.autoplay = true;
    vid.flipY = true;
    vid.play();
    return vid;
  })

  useEffect(() => {

    spotLight.current.target.position.set(0, 0, 13.55);
    spotLight.current.target.updateMatrixWorld();

    if (tv !== undefined) {
      tv.material = new THREE.MeshBasicMaterial();
      tv.material.color = new THREE.Color(0x919191);
      const videoTextureMap = new THREE.VideoTexture(video);
      videoTextureMap.flipY = false;
      tv.material.map = videoTextureMap;
    }

  }, [])

  return (
    <>
      <CameraController />
      <spotLight ref={spotLight} position={[0.0, 7.0, 3.3504372]} color="white" intensity={5} angle={0.4} penumbra={1} />
      <group ref={target} position={[0.0, 2.0, 10.3504372]} />
      {<CategoryItem object={agriculture_RT} categoryId={CategoryId.AGRICULTURE} labelOffset={[0, -1.45, 0]} categoryName="AGRICULTURE" />}
      {<CategoryItem object={Construction_RT} categoryId={CategoryId.CONSTRUCTION} labelOffset={[-1, -1.15, 0]} categoryName="CONSTRUCTION" />}
      {<CategoryItem object={Material_Handling_RT} categoryId={CategoryId.MATERIAL_HANDLING} labelOffset={[1, -1.15, 0]} categoryName="MATERIAL HANDLING" />}
      {(location === 3 && glbFile !== "") && <Suspense fallback={<Html position={[0, 2.80, 10]}><LoaderTire /></Html>}><TireInspectorItem glbFile={glbFile} /></Suspense>}
      <primitive object={scene} rotation={[0, 0, 0]} dispose={null} />
      <Html center transform position={[0, 0.05, -10]} scale={[0.2, 0.2, 0.2]}>
        <img src={agricultureImage} onClick={() => {
          const categoryKey = Object.keys(tyresAndCategories)[0]
          setSelectedCategory(categoryKey);
          setSelectedSubCategory(null);
          setSelectedTire(null)
          setRoutingByObject(true);
        }}></img>
      </Html>
      <Html center transform position={[-7.72, 0.05, -15]} scale={[0.2, 0.2, 0.2]}>
        <img src={constructionImage} onClick={() => {
          const categoryKey = Object.keys(tyresAndCategories)[1]
          setSelectedCategory(categoryKey);
          setSelectedSubCategory(null);
          setSelectedTire(null)
          setRoutingByObject(true);
        }}></img>
      </Html>
      <Html center transform position={[7.18, 0.05, -15]} scale={[0.2, 0.2, 0.2]}>
        <img src={materialImage} onClick={() => {
          const categoryKey = Object.keys(tyresAndCategories)[2]
          setSelectedCategory(categoryKey);
          setSelectedSubCategory(null);
          setSelectedTire(null)
          setRoutingByObject(true);
        }}></img>
      </Html>
      <Html center position={[0, 0, -1000]}>
        <div className={props.didUserClickTheCanvas ? Styleclass.handhide : Styleclass.container}>
          <img className={Styleclass.handstyle} src={Hand} alt="moving hand" />
        </div>
      </Html>
    </>
  )
}

useGLTF.preload('/GLB/GRI_Scene.glb')