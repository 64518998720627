import React, {useState,useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import QRCode from 'react-qr-code';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  closebtn: {
    color: '#113b77',
  },
}));

function QRCodePopup({ qrCodeOpen, setQrCodeOpen, shortURL }) {
  const classes = useStyles();
  const [qrSize,setQrSize] = useState(200)

  const handleClose = () => {
    setQrCodeOpen(false);
  };

  useEffect(() => {
    const winHeight = (2*window.innerHeight)/5;
    setQrSize(winHeight);
  },[]);

  return (
    <>
      <div>
        <Dialog
          open={qrCodeOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Scan QR code for AR experience from your phone"}</DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode value={shortURL} size={qrSize} level={'L'} includeMargin={true} />
          </div>
          <DialogActions>
            <Button onClick={handleClose} className={classes.closebtn}>
              Close
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default QRCodePopup;