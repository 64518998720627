import { OrbitControls, PerspectiveCamera, useGLTF } from '@react-three/drei';
import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from 'react'
import { useFrame, useThree, createPortal } from '@react-three/fiber';
import { useEquirectangularHDR } from '../main_screen/SkyBox';
import * as THREE from 'three'
import UseStore from './../../stores/ShowroomStore'

const TireInspectorItem = (props) => {

  const objectRef = useRef();
  const controlRef = useRef();

  const virtualScene = useMemo(() => new THREE.Scene(), [props.glbFile])

  const virtualCam = useRef()
  const { gl, camera } = useThree()
  const { scene: tire, nodes } = useGLTF(props.glbFile)

  let skybox = useEquirectangularHDR(); //useRenderedSky();

  const isTireZoomed = UseStore((state) => state.isTireZoomed);
  const [tireMesh, setTireMesh] = useState(null);

  useEffect(() => {

    for( var key in nodes) {

      let object = nodes[key];
      if(object && object.isMesh) {
        object.material.envMap = skybox;
        setTireMesh(object);
      }
    }
  }, [tire])

  useFrame(() => {
    if (isTireZoomed) {
      gl.render(virtualScene, virtualCam.current);
    } else if(objectRef.current) {
      objectRef.current.rotation.y += 0.005
      gl.render(virtualScene, camera);
    }
  }, 1)

  useLayoutEffect(() => {

    if(objectRef.current) {
      const box = tireMesh.geometry.boundingBox;//new THREE.Box3().setFromArray(tireMesh);
      //const size = box.getSize(new THREE.Vector3()).length();
      const height = Math.abs(box.max.y - box.min.y);
      const scale = 6.0 / height;
  
      if (!isTireZoomed) {
        objectRef.current.scale.set(scale, scale, scale);
        objectRef.current.position.set(-0.0, 1.8, 15);
  
      } else {
        objectRef.current.position.set(0, 0, 0)
        objectRef.current.scale.set(1, 1, 1);
        controlRef.current.minDistance = height;
        controlRef.current.maxDistance = height * 2;
        virtualCam.current.position.y = 0;
        virtualCam.current.position.z = height * 2;
      }
    }   
  }, [isTireZoomed, tireMesh])

  return createPortal(
    <group key={props.glbFile}>
      <PerspectiveCamera ref={virtualCam} makeDefault={false} position={[0, 0, 1]} />
      <pointLight position={[-10, -10, -10]} color="white" intensity={0.5} />
      <pointLight position={[0, -10, 10]} intensity={0.5} />
      <directionalLight position={[0, -10, 0]} color="white" intensity={1.0} />
      <directionalLight position={[0, 10, 0]} color="white" intensity={1.0} />
      <ambientLight intensity={1} />
      {tireMesh && <primitive position={[-0.0, 1.8, 15]} rotation={[0, 1.5708, 0]}
        object={tireMesh} ref={objectRef}
        //onClick={() => setIsTireZoomed(true)}
      /> }
      {isTireZoomed && <OrbitControls autoRotate ref={controlRef} camera={virtualCam.current}/>}
    </group>,
    virtualScene
  )
}

export default TireInspectorItem;