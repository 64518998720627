import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Slider from "./slider";
import UseStore from './../../stores/ShowroomStore';
import {useLocation} from 'react-router-dom'

import {
  Box
} from '@material-ui/core';

import "swiper/css/swiper.css";
import { object } from 'yup/lib/locale';

const useStyles = makeStyles((theme) => ({
  cardFlowHolder: {
    margin: '0 auto',
  },
}));

export default function CoverFlow({ clickFunction, itemType, selectedType }) {
  const location = UseStore((state) => state.location);
  const tyresAndCategories = UseStore(state => state.tyresAndCategories);
  const selectedCategory = UseStore(state => state.selectedCategory);
  const selectedSubCategory = UseStore(state => state.selectedSubCategory);
  const classes = useStyles();
  const [redrawer, setRedrawer] = React.useState(false);
 // const path = useLocation()

  const priorityOrder = {
    "agriculture-tires": {
      "tractor": { "green-xlr-65-r-1w": 4 }
    },
    "construction-tires": {
      "excavators": { "grip-ex-ex222-e-2": 25 }
    },
    "material-handling-tires": {
      "forklifts": { "ultimate-xt-xt": 36 }
    },
  }

  let activeSlideKey;

  React.useEffect(() => {
    if(location === 2) { setTimeout(() => { setRedrawer(!redrawer) }, 300) }
  },[location])

  const reorderInPriorityOrder = (itemsObject, isPriorityApplied) => {
    const items = Object.values(itemsObject)
    if (items.length < 2) {
      activeSlideKey = `slide_${items[0].key}`
      return items
    }
    const priorityKey = Object.keys(isPriorityApplied)
    const middleIndex = Math.round(items.length / 2) - 1
    const temp = items[middleIndex]
    const priorityElementIndex = items.findIndex(element => element.key == priorityKey)
    if (priorityElementIndex < 0) return items
    items[middleIndex] = items[priorityElementIndex]
    activeSlideKey = `slide_${items[priorityElementIndex].key}`
    items[priorityElementIndex] = temp
    return items
  }

  let itemsToDisplay;
  let itemsToDisplayOrderd;
  if ((selectedCategory || selectedSubCategory)) {
    if (selectedSubCategory) {
      itemsToDisplay = tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres
      if (selectedType) {
        let filteredItemsToDisplay = {}
        Object.keys(itemsToDisplay).forEach(tyreKey => {
          if (itemsToDisplay[tyreKey].tyre_type_id == selectedType && itemsToDisplay[tyreKey].isSelected) filteredItemsToDisplay[tyreKey] = itemsToDisplay[tyreKey]
        })
        itemsToDisplay = filteredItemsToDisplay
      }
      else{
        let assinedTiresToDisplay = {}
        Object.keys(itemsToDisplay).forEach(tyreKey => {
          if (itemsToDisplay[tyreKey].isSelected)  assinedTiresToDisplay[tyreKey] = itemsToDisplay[tyreKey]
        })
        itemsToDisplay = assinedTiresToDisplay
      }
      const isPriorityApplied = priorityOrder[selectedCategory][selectedSubCategory]
      itemsToDisplayOrderd = isPriorityApplied? reorderInPriorityOrder(itemsToDisplay, isPriorityApplied) : Object.values(itemsToDisplay)
    } else {
      itemsToDisplay = tyresAndCategories[selectedCategory].machineries
      const isPriorityApplied = priorityOrder[selectedCategory]
      itemsToDisplayOrderd = isPriorityApplied? reorderInPriorityOrder(itemsToDisplay, isPriorityApplied) : Object.values(itemsToDisplay)
    }
  }

  return (
    <Grid className={classes.cardFlowHolder} item  style={{'width': '100%', 'display':'flex', 'justifyContent':'center'}}>
      <Box style={{'width': '74rem', 'maxWidth':'100%'}}>
        {(selectedCategory || selectedSubCategory) &&
          <Slider
          //  key={path.pathname}
            activeSlideKey = {activeSlideKey}
            id="three-d-overflow-effect"
            items={itemsToDisplayOrderd}
            clickFunction={clickFunction} />}
      </Box>
    </Grid>
  );
}