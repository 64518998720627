import React from 'react';
// import Styleclass from './sharestyle.module.css';
import Styleclass from './zoominstyle.module.css';
import UseStore from "../../../stores/ShowroomStore";
import FullscreenIcon from '@material-ui/icons/Fullscreen';

function ZoomInButtonMobile() {
    const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

    return (
        <>
            <div className={Styleclass.container}>
                <div className={Styleclass.labelContainer}>
                    <p className={Styleclass.textStyle}>Virtual mode</p>
                </div>
                <button className={Styleclass.spbutton} onClick={()=>setIsTireZoomed(true)}>
                    <FullscreenIcon style={{ color: "#fff", display: "flex" }} />
                </button>
            </div>
        </>
    );
}

export default ZoomInButtonMobile;