import React from "react";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import ThreeDRotationOutlinedIcon from "@material-ui/icons/ThreeDRotationOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ShareIcon from "@material-ui/icons/Share";
import UseStore from "./../../stores/ShowroomStore";

import { makeStyles } from "@material-ui/core/styles";

import InquiryPopup from "./InquiryPopup";
import QRCodePopup from "./QRCodePopup";

function ARicon() {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="24px" height="24px" viewBox="0 0 72.000000 82.000000"
      preserveAspectRatio="xMidYMid meet">
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
      <g transform="translate(0.000000,82.000000) scale(0.100000,-0.100000)"
        fill="#016937" stroke="#016937" stroke-width="15">
        <path d="M283 794 c-27 -16 -43 -32 -43 -45 0 -24 11 -24 60 1 l40 20 0 -56
c0 -47 3 -55 18 -52 13 2 18 15 20 55 l3 52 39 -20 c71 -37 85 4 16 45 -56 33
-99 33 -153 0z"/>
        <path d="M75 677 c-11 -7 -22 -14 -25 -17 -3 -3 -14 -10 -25 -16 -17 -9 -20
-23 -23 -88 -3 -71 -1 -76 18 -76 17 0 20 6 20 45 l0 46 40 -23 c30 -18 42
-20 51 -11 15 15 5 41 -18 50 -43 15 -44 25 -8 50 20 14 34 30 32 36 -5 17
-38 19 -62 4z"/>
        <path d="M577 675 c-4 -9 8 -23 34 -38 l40 -24 -35 -21 c-37 -22 -47 -48 -21
-58 8 -3 28 4 44 15 16 12 32 21 35 21 3 0 6 -20 6 -45 0 -38 3 -45 20 -45 18
0 20 7 20 74 l0 75 -54 30 c-59 34 -81 38 -89 16z"/>
        <path d="M247 493 c-18 -18 -5 -44 31 -61 48 -22 50 -26 58 -94 5 -47 10 -58
25 -58 15 0 19 10 21 63 l3 62 47 25 c45 24 61 51 38 65 -5 3 -32 -7 -60 -22
l-52 -28 -49 28 c-55 30 -53 30 -62 20z"/>
        <path d="M5 347 c-4 -8 -5 -45 -3 -83 l3 -69 57 -30 c47 -24 60 -27 70 -17 13
14 1 29 -48 58 -17 11 -16 13 19 34 20 12 37 28 37 36 0 18 -37 18 -72 0 l-27
-14 -3 46 c-3 45 -23 68 -33 39z"/>
        <path d="M687 354 c-4 -4 -7 -27 -7 -51 l0 -44 -30 16 c-16 8 -39 15 -50 15
-30 0 -22 -31 13 -48 36 -17 34 -26 -9 -51 -19 -12 -34 -27 -32 -34 8 -22 42
-18 96 13 l53 31 -3 76 c-3 70 -13 95 -31 77z"/>
        <path d="M340 105 c0 -25 -3 -45 -6 -45 -3 0 -17 9 -31 20 -36 28 -65 27 -61
-2 5 -40 135 -98 153 -68 3 5 22 17 41 26 40 19 52 40 35 57 -9 9 -21 7 -51
-10 l-40 -21 0 44 c0 37 -3 44 -20 44 -17 0 -20 -7 -20 -45z"/>
      </g>
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  speedDialFab: {
    background: theme.palette.griBlueMain,
  },
  tooltipOpenStyle: {
    width: "1000px",
  },
  customStaticTooltip: {
    color: "rgba(1,105,55,255)",
    // color: theme.palette.griGreenMain,
    background: "rgba(6, 104, 57, 0.04)",
    backdropFilter: "blur(5px)",
  },
  customStaticTooltipLabel: {
    width: "max-content",
    background: "rgba(6, 104, 57, 0.04)",
    backdropFilter: "blur(5px)",
    color: "rgba(35,57,123,255)",
    // color: theme.palette.griLightMain
  },
}));

function CallToAction({ qrItem }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { enqueueSnackbar } = window.snack;
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const selectedTire = UseStore((state) => state.selectedTire);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);

  const [inquiryOpen, setinquiryOpen] = React.useState(false);
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDownloadSpec = () => {
    return window.open(
      `https://gritires.com/storage/${tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].international_standard_pdf}`,
      "_blank"
    );
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location);
    enqueueSnackbar("URL copied to clipboard!", {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
  };

  const handleInquiry = () => {
    setinquiryOpen(true);
  };

  const handleQrCode = () => {
    setQrCodeOpen(true);
  }

  const actions = [
    {
      icon: <ARicon />,
      name: "View in AR",
      handleAction: handleQrCode,
    },
    {
      icon: <LiveHelpOutlinedIcon />,
      name: "Inquire",
      handleAction: handleInquiry,
    },
    {
      icon: <SystemUpdateAltIcon />,
      name: "Download Specs",
      handleAction: handleDownloadSpec,
    },
    { icon: <ShareIcon />, name: "Share", handleAction: handleShare },
  ];

  return (
    <SpeedDial
      ariaLabel="SpeedDial"
      className={classes.speedDial}
      classes={{
        fab: classes.speedDialFab,
      }}
      icon={<SettingsOutlinedIcon />}
      // onClose={handleClose}
      // onOpen={handleOpen}
      onClick={toggleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          classes={{
            fab: classes.customStaticTooltip,
            staticTooltipLabel: classes.customStaticTooltipLabel,
          }}
          tooltipOpen
          onClick={() => action.handleAction && action.handleAction()}
        />
      ))}
      {inquiryOpen === true && (
        <InquiryPopup iqopen={inquiryOpen} setiqopen={setinquiryOpen} />
      )}
      {qrCodeOpen === true && (
        <QRCodePopup qrItem={qrItem} qrCodeOpen={qrCodeOpen} setQrCodeOpen={setQrCodeOpen} />
      )}
    </SpeedDial>
  );
}

export default CallToAction;
