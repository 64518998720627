import Pool from '../UserPool';

const baseURL = process.env.REACT_APP_API_ENDPOINT

async function fetchClient(endpoint, { body, customConfig } = {}, idToken, isProtected) {
    const headers = { 'Content-Type': 'application/json' }
    
    if (isProtected) {
        try {
            if (idToken) {
                headers.Authorization = `Bearer ${idToken}`
            }
            else {
                const token = await getIdToken()
                headers.Authorization = `Bearer ${token}`
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers
        }
    }

    if (body) {
        config.body = JSON.stringify(body)
    }

    return fetch(`${baseURL}/${endpoint}`, config)
        .then(async response => {
            const data = await response.json()
            if (response.ok) {
                return data
            } else {
                let error;
                if(data.error) {
                    error = data.error
                } else error = data.message
                return Promise.reject(error)
            }
        })
}

const getIdToken = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = Pool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else if (!session.isValid()) {
                    reject("Invalid session.");
                } else {
                    const idToken = session.getIdToken().getJwtToken()
                    resolve(idToken)
                }
            });
        } else {
            reject("User not found.");
        }
    })
}

export default fetchClient
