import { useCallback, useEffect, useState } from 'react'
import lerp from 'lerp'
import { useFrame, useThree } from '@react-three/fiber'
import UseStore from '../stores/ShowroomStore'
import { useGesture } from "react-use-gesture";

import {Locations} from '../res/Constants'


function HomeSwipeState(bounds, props) {
  var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
  const [y, set] = useState(0);
  const fn = useCallback(
    ({ vxvy: [vx], last }) => {

      if(supportsTouch) {
        if(last && vx < 0.3) {
          set(y < 0 ? -1 : y - 1 )
        }
        else if (last && vx > 0.3) {
          set(y > 0 ? 1 : y + 1 )
        }
      }      
    },
    [bounds, y, set]
  );
  const bind = useGesture({ onDrag: fn }, props);
  useEffect(() => props && props.domTarget && bind(), [props, bind]);
  return [y, bind];
}


export default function CameraController(props) {

  const { gl, scene, camera } = useThree();

  const [animationState, setAnimationState] = useState({
      animateCamera: false,
      targetPosX: 0, targetPosY: 0, targetPosZ: 0,
      targetRotX: 0, targetRotY: 0, targetRotZ: 0,
      step: 0.05
  })

  const location = UseStore(state => state.location);
  const [homeState] = HomeSwipeState([-100, 100], { domTarget: window });
  
  useFrame((state, delta) => {
    // mixer.update(delta)
    if (animationState.animateCamera) {
      camera.position.x = lerp(camera.position.x, animationState.targetPosX, animationState.step);
      camera.position.y = lerp(camera.position.y, animationState.targetPosY, animationState.step);
      camera.position.z = lerp(camera.position.z, animationState.targetPosZ, animationState.step);
      camera.rotation.x = lerp(camera.rotation.x, animationState.targetRotX * 0.0174533, animationState.step);
      camera.rotation.y = lerp(camera.rotation.y, animationState.targetRotY * 0.0174533, animationState.step);
      camera.rotation.z = lerp(camera.rotation.z, animationState.targetRotZ * 0.0174533, animationState.step);
        
      if (Math.abs(camera.position.x - animationState.targetPosX) < 0.01 &&
        Math.abs(camera.position.y - animationState.targetPosY) < 0.01 &&
        Math.abs(camera.position.z - animationState.targetPosX) < 0.01 &&
        Math.abs(camera.rotation.x - animationState.targetRotX) < 0.01 &&
        Math.abs(camera.rotation.y - animationState.targetRotY) < 0.01 &&
        Math.abs(camera.rotation.z - animationState.targetRotZ) < 0.01) {
        setAnimationState({
          ...animationState,
          animateCamera: false
        });
      }
    }

    gl.autoClear = true
		gl.render(scene, camera)
		gl.autoClear = false
		gl.clearDepth()

  }, 0);

  useEffect(() => {
      if (location === Locations.CATEGORIES) {

        if(homeState === 0) {
          setAnimationState({
            ...animationState,
            animateCamera: true,
            targetPosX: 0,
            targetPosY: 3.32515,
            targetPosZ: 0,
            targetRotX: 0,
            targetRotY: 0,
            targetRotZ: 0,
            step: 0.05
          });
        } else if(homeState === -1) {
          setAnimationState({
            ...animationState,
            animateCamera: true,
            targetPosX: 7.5,
            targetPosY: 3.32515,
            targetPosZ: -3,
            targetRotX: 0,
            targetRotY: 0,
            targetRotZ: 0,
            step: 0.05
          });
        } else {
          setAnimationState({
            ...animationState,
            animateCamera: true,
            targetPosX: -7.5,
            targetPosY: 3.32515,
            targetPosZ: -3,
            targetRotX: 0,
            targetRotY: 0,
            targetRotZ: 0,
            step: 0.05
          });
        }
        
      } else if (location === Locations.SUBCATEGORIES) {
        setAnimationState({
          ...animationState,
          animateCamera: true,
          targetPosX: 0,
          targetPosY: 3.32515,
          targetPosZ: 0,
          targetRotX: 0,
          targetRotY: -90,
          targetRotZ: 0,
          step: 0.05
        });
      } else if (location === Locations.TIRE_PICKER) {
        //need to repeat this in order to navigate back from the inspector
        setAnimationState({
          ...animationState,
          animateCamera: true,
          targetPosX: 0,
          targetPosY: 3.32515,
          targetPosZ: 0,
          targetRotX: 0,
          targetRotY: -90,
          targetRotZ: 0,
          step: 0.05
        });
      } else if (location === Locations.TIRE_INSPECTOR) {

          setAnimationState({
            ...animationState,
            animateCamera: true,
            targetPosX: 0,
            targetPosY: 3.32515,
            targetPosZ: 0,
            targetRotX: 0,
            targetRotY: -180,
            targetRotZ: 0,
            step: 0.05
          });
      } else {
        setAnimationState({
          ...animationState,
          animateCamera: true,
          targetPosX: 3.75463,
          targetPosY: 6.1643,
          targetPosZ: -13.63771,
          targetRotX: 0,
          targetRotY: 0,
          targetRotZ: 0,
          step: 0.05
        });
      }

  }, [location, homeState])

  useEffect(() => {
    camera.position.x = 3.75463;
    camera.position.y = 6.1643;
    camera.position.z = -13.63771;
  },[]);

  return (
    <group/>
  )
}