import React, { useEffect } from 'react'

import Login from './components/Login'

import './BaseStyle.css';

import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect
} from 'react-router-dom'

import UseStore from './stores/ShowroomStore'
import AuthStore from './stores/AuthStateStore';

function Router() {
  const location = useLocation();
  const history = useHistory();

  const isAuthenticated = AuthStore(state => state.isAuthenticated);

  const endShowroom = UseStore(state => state.actions.endShowroom);
  const startShowroom = UseStore(state => state.actions.startShowroom);
  const moveToTireCategories = UseStore(state => state.actions.moveToTireCategories);
  const moveToTirePicker = UseStore(state => state.actions.moveToTirePicker);
  const moveToTireInspector = UseStore(state => state.actions.moveToTireInspector);

  const selectedCategory = UseStore(state => state.selectedCategory);
  const selectedSubCategory = UseStore(state => state.selectedSubCategory);
  const selectedTire = UseStore(state => state.selectedTire);

  const routingByObject = UseStore(state => state.routingByObject);
  const setRoutingByObject = UseStore(state => state.setRoutingByObject);
  const setSelectedCategory = UseStore(state => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(state => state.setSelectedSubCategory);
  const setSelectedTire = UseStore(state => state.setSelectedTire);

  //handle direct address bar entry
  useEffect(() => {
    let splittedPath = location.pathname.split('/').filter(item => item !== '')

    if(isAuthenticated){
      switch(splittedPath.length) {
        case 2:
          if(!selectedCategory) setSelectedCategory(splittedPath[1]);
          setSelectedSubCategory(null)
          moveToTireCategories();
          break;
        case 3:
          if(!selectedCategory) {
            setSelectedCategory(splittedPath[1]);
            setSelectedSubCategory(splittedPath[2]);
          }
          moveToTirePicker();
          break;
        case 4:
          if(!selectedCategory) {
            setSelectedCategory(splittedPath[1]);
            setSelectedSubCategory(splittedPath[2]);
            setSelectedTire(splittedPath[3]);
          } 
          moveToTireInspector();
          break;
        default:
          history.push('/home');
          startShowroom();
          break;
      }
    } else {
      history.push('/login');
      endShowroom();
    }
    setRoutingByObject(false);
  }, [location.pathname])


  //handle object click
  useEffect(() => {
    if(routingByObject){
      let currentRoute = '/login';
      if(isAuthenticated){
        currentRoute = '/home/';
        if(selectedCategory){
          currentRoute += selectedCategory;
          if(selectedSubCategory) {
            currentRoute += '/'+ selectedSubCategory;
            if(selectedTire) currentRoute += '/'+ selectedTire;
          } 
        } 
      }
      
      history.push(currentRoute);
    }
  },[routingByObject])

  return (
    <>
      <Switch>
        <Route exact path="/" render={() => (<Redirect to="/home" />)} />     
        <Route exact path="/login"><Login/></Route>
        <Route path={`/home/:catId`}></Route>
        <Route path="*"></Route>
      </Switch>
    </>
  )
}


export default Router