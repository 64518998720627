import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    griGreenMain: '#7aae40',
    griGreenSecondary: '#a5cc47',
    griBlueMain: '#0f3871',
    griLightMain: '#ffffff',
    transparency: {
      dark1: 'rgba(0,0,0,0.1)',
      dark2: 'rgba(0,0,0,0.2)',
      green85: 'rgba(122, 174, 64, 0.85)'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        color: "#23397B",
        backgroundColor: "#0668390A",
        padding: "4px 16px",
        margin: "0px 8px 0px 0px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      lsm:768,
      md: 960,
      lg: 1280,
      xlg: 1400,
      xl: 1920,
    },
  }
})

export default theme