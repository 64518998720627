import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles'
import './index.css';
import App from './App';
import muiTheme from './theme/muiTheme';
import TagManager from 'react-gtm-module';
import { SnackbarProvider } from "notistack";

const tagManagerArgs = {
  gtmId: 'GTM-5ZLXHKP',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <SnackbarProvider maxSnack={1}>
    <App />
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
