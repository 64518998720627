import React from 'react';
import TireARPng from '../../res/tirearicon.png';
import Styleclass from './arbuttonstyle.module.css';
import QRCodePopup from './QRCodePopup';
import AuthStore from "./../../stores/AuthStateStore";
import Pool from "./../../UserPool"
import { CircularProgress } from '@material-ui/core';
import { getShortLink } from '../../server/UserAPI';

function ArButton({ qrItem, glbFile, usdzFile, path }) {

  const { enqueueSnackbar } = window.snack
  const [qrOpen, setQrOpen] = React.useState(false);
  const [shortURL, setShortURL] = React.useState(null);
  const [isloading, setIsloading] = React.useState(false);
  const email = AuthStore((state) => state.email);
  const idToken = AuthStore((state) => state.idToken);
  const showroomUrl = process.env.REACT_APP_SHOWROOM_URL

  let platform = window.navigator.platform;
  let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = null;

  if (/iPhone/g.test(navigator.userAgent)) {
    os = 'iphone';
  }
  else if (/iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    os = 'ipad';
  }
  else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  }
  else if (/android/i.test(navigator.userAgent)) {
    os = 'android';
  }
  else {
    os = 'device';
  }

  let fallback_url = "";

  const handleQr = () => {
    setIsloading(true)
    if (qrItem !== "") {
      if (idToken){ // idToken exists that means this is the qr scan flow
        let longUrl = `${showroomUrl}${path}?__id_token=${idToken}&__user_name=${email}`
        getShortLink(longUrl, idToken)
          .then((res) => {
            if (res["status"] == 7) {
              setShortURL(res["shortLink"])
              setQrOpen(true);
            }
            setIsloading(false)
          })
          .catch((err) => {
            setIsloading(false)
            console.log(err)
            alert("Error while loading QR code")
          });
      }
      else{
        setTokens()
          .then((idToken) => {
            let longUrl = `${showroomUrl}${path}?__id_token=${idToken}&__user_name=${email}`
            return getShortLink(longUrl, idToken)
          })
          .then((res) => {
            if (res["status"] == 7) {
              setShortURL(res["shortLink"])
              setQrOpen(true);
            }
            setIsloading(false)
          })
          .catch((err) => {
            console.log(err)
            setIsloading(false)
            alert("Error while loading QR code")
          });
      }
    }
    else {
      setIsloading(false);
      enqueueSnackbar("AR model is not available", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  }

  const setTokens = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = Pool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else if (!session.isValid()) {
                    reject("Invalid session.");
                } else {
                    const idToken = session.getIdToken().getJwtToken()
                    resolve(idToken)
                }
            });
        } else {
            reject("User not found.");
        }
    })
}

  const arTipOpen = true;

  return (
    <>
      { os === 'android' && 
        <a className={Styleclass.atag} href={`intent://arvr.google.com/scene-viewer/1.0?file=${glbFile}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${fallback_url};end;`}>
          <button className={Styleclass.arbutton}>
            <img src={TireARPng} alt="ar icon" width="24px" height="24px" />
            <p className={Styleclass.btntext}>Place Anywhere</p>
          </button>
        </a>
      }

      { (os === 'iphone' || os === 'ipad') &&
        <a className={Styleclass.atag} rel="ar" href={usdzFile}>
          <button className={Styleclass.arbutton}>
              <img src={TireARPng} alt="ar icon" width="24px" height="24px" />
              <p className={Styleclass.btntext}>Place Anywhere</p>
          </button>      
        </a>
      }

      {
        (os === 'windows' || os === 'device') && 
          <button className={Styleclass.arbutton} onClick={handleQr} disabled={isloading}> 
          { isloading ? <CircularProgress style={{'color': 'white'}} size='1.5rem' /> : <><img src={TireARPng} alt="ar icon" width="24px" height="24px" /><p className={Styleclass.btntext}>Place Anywhere</p></>}
          </button>
      }

      {
        qrOpen === true && <QRCodePopup shortURL={shortURL} qrCodeOpen={qrOpen} setQrCodeOpen={setQrOpen} />
      }

    </>
  );
}

export default ArButton;