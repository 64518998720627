import React, { useState, useRef } from 'react'
import { useFrame } from '@react-three/fiber';
import UseStore from '../../stores/ShowroomStore';
import { useEquirectangularHDR } from '../main_screen/SkyBox';

const CategoryItem = (props) => {
  const { enqueueSnackbar } = window.snack;

  const objectRef = useRef();
  let skybox = useEquirectangularHDR(); //useRenderedSky();

  const isLoading = UseStore(state => state.isLoading);
  const setRoutingByObject = UseStore(state => state.setRoutingByObject);
  const setSelectedCategory = UseStore(state => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(state => state.setSelectedSubCategory);
  const setSelectedTire = UseStore(state => state.setSelectedTire);
  const tyresAndCategories = UseStore(state => state.tyresAndCategories);

  props.object.traverse(object => {
    if (object.isMesh) {
      object.material.envMap = skybox;
    }
  })

  const setTireCategory = () => {
    const categoryKey = Object.keys(tyresAndCategories)[props.categoryId - 1]
    if (tyresAndCategories[categoryKey].isSelected) {
      setSelectedTire(null);
      setSelectedSubCategory(null);
      setSelectedCategory(categoryKey);
      setRoutingByObject(true);
    } else {
      enqueueSnackbar(`Sorry! You are not authorized to explore the ${categoryKey}.`,
        {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }
      );
    }
  }

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)

  useFrame(() => {
    if(hovered){
      objectRef.current.rotation.y += 0.005
    }
  })

  return (
    <>
      <primitive object={props.object} ref={objectRef} onPointerOut={(e) => setHover(false)} onPointerOver={(e) => { setHover(true) }} onClick={(!isLoading) && setTireCategory} dispose={null} />
    </>
  )
}

export default CategoryItem;