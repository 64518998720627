export const Locations = {
  INIT: -1,
  CATEGORIES: 0,
  SUBCATEGORIES: 1,
  TIRE_PICKER: 2,
  TIRE_INSPECTOR: 3,
};

export const CategoryId = {
  AGRICULTURE: 1,
  CONSTRUCTION: 2,
  MATERIAL_HANDLING: 3,
}