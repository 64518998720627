import React, { useState } from 'react';
import Styleclass from './arbuttonstyle.module.css'
import UseStore from "../../stores/ShowroomStore";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

function ZoomButton({isZoomed}) {
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);


    return (
        <button className={Styleclass.arbutton} onClick={()=>setIsTireZoomed(!isZoomed)}>
            {isZoomed ?
                <>
                    <FullscreenExitIcon style={{ 'color': 'white' }} />
                    <p className={Styleclass.btntext}>Exit</p>
                </> :
                <>
                    <FullscreenIcon style={{ 'color': 'white' }} />
                    <p className={Styleclass.btntext}>Virtual Mode</p>
                </>}
        </button>
    );
}

export default ZoomButton;