import React from "react";
import StyleClass from "./LoaderStyle.module.css";
import logo from "./grilogo.svg";
import icon from "./loadingicon.svg";

function LoadSpinner() {
  return (
    <div className={StyleClass.container}>
      <div className={StyleClass.box}>
        <span className={StyleClass.logo}>
          <img src={logo} width="150px" height="100px" alt="gri" />
        </span>
      </div>
    </div>
  );
}

export default LoadSpinner;
