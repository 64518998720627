import React, { useState } from 'react';
import ShareIcon from '@material-ui/icons/Share';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import TuneIcon from '@material-ui/icons/Tune';
import Styleclass from './buttongroupstyle.module.css';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import MetricsPopup from '../MetricsPopup';
import UseStore from '../../../stores/ShowroomStore';
import InquiryPopup from '../InquiryPopup';


function ButtonGroupMobile() {
  const [metricOpen, setMetricOpen] = useState(false);
  const [inquiryOpen, setinquiryOpen] = useState(false);
  const location = UseStore((state) => state.location);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const selectedTire = UseStore((state) => state.selectedTire);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const { enqueueSnackbar } = window.snack;

  let pdfLink= null;
  if (location === 3) {
    const category = tyresAndCategories[selectedCategory];
    if (category) {
      const sub = category.machineries[selectedSubCategory];
      if (sub) {
        const tyre = sub.tyres[selectedTire];
        if (tyre) {
            pdfLink = tyre.international_standard_pdf;
        }
      }
    }
  }

  const handleDownloadSpec = () => {
    return window.open(
        `https://gritires.com/storage/${tyresAndCategories[selectedCategory].machineries[selectedSubCategory].tyres[selectedTire].international_standard_pdf}`,
        "_blank");
  };

  const handleShare = () => {
      navigator.clipboard.writeText(window.location);
      enqueueSnackbar("URL copied to clipboard!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
  };

  return (
    <>
    <div className={Styleclass.container}>
      <BottomNavigation showLabels className={Styleclass.navcontainer}>
        <BottomNavigationAction style={{color: '#113b77'}} label="Metrics" icon={<TuneIcon />} onClick={()=> setMetricOpen(true)} />
        {pdfLink !== null && <BottomNavigationAction style={{color: '#113b77'}} label="Specs" icon={<SystemUpdateAltOutlinedIcon />} onClick={handleDownloadSpec}/>}
        <BottomNavigationAction style={{color: '#113b77'}} label="Inquire" icon={<EmailOutlinedIcon />} onClick={()=> setinquiryOpen(true)}/>
        <BottomNavigationAction style={{color: '#113b77'}} label="Share" icon={<ShareIcon /> } onClick={handleShare} />
      </BottomNavigation>

      {metricOpen === true && <MetricsPopup mtrOpen={metricOpen} setMtrOpen={setMetricOpen} />}
      {inquiryOpen === true && <InquiryPopup iqopen={inquiryOpen} setiqopen={setinquiryOpen} />}
    </div>
    </>
  );
}

export default ButtonGroupMobile;