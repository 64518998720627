import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import Styleclass from './sharestyle.module.css';
import Tooltip from '@material-ui/core/Tooltip';

function ShareButton() {
    const { enqueueSnackbar } = window.snack;

    const handleShare = () => {
        navigator.clipboard.writeText(window.location);
        enqueueSnackbar("URL copied to clipboard!", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
    };

    const shareTipOpen = true;

    return (
        <>
            <div className={Styleclass.container}>
                <div className={Styleclass.labelContainer}>
                    <p className={Styleclass.textStyle}>Share</p>
                </div>
                <button className={Styleclass.spbutton} onClick={handleShare}>
                    <ShareIcon style={{ color: "#fff", display: "flex" }} />
                </button>
            </div>
        </>
    );
}

export default ShareButton;