import React from "react";
import StyleClass from "./LoaderTireStyle.module.css";

function LoadSpinner() {
  return (
    <div className={StyleClass.container}>
    <div className={StyleClass.secondbox}>
      <h1 className={StyleClass.subtitle}>VIRTUAL TIRE IS LOADING...</h1>
    </div>
    </div>
  );
}

export default LoadSpinner;