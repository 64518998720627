import React, { useRef, useEffect, useMemo } from "react"
import { useHelper } from '@react-three/drei'
import { SpotLightHelper } from 'three'

const PostProcessing = (props) => {

    const spotLight = useRef();
    const target = useRef();

    //useHelper(spotLight, SpotLightHelper)

    return (
        <>
            <spotLight position={[14.862771, 7, 5.739182]} ref={spotLight} target={target.current}
                angle={0.45}
                intensity={1}
                penumbra={0.6}/>
            <group ref={target} position={[14.862771, 1.0, 5.739182]} />
        </>
    )
}

export default PostProcessing;