import React from 'react';
import Styleclass from './zoomoutstyle.module.css';
import UseStore from "../../../stores/ShowroomStore";
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';


function ZoomOutButtonMobile() {
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

    return (
        <div className={Styleclass.container}>
            <button className={Styleclass.zobutton} onClick={()=>setIsTireZoomed(false)}>
                <FullscreenExitIcon style={{'color': 'white'}} />
                <p className= {Styleclass.btntext}>Exit</p>
            </button>
        </div>
    );
}

export default ZoomOutButtonMobile;