import create from "zustand"

const AuthStore = create(set => ({
  isAuthenticated: false,
  setAuthenticatedState: (authState) => set(state => ({ isAuthenticated: authState })),
  userName: "",
  setUserName: (authState) => set(state => ({ userName: authState })),
  email: "",
  setUserEmail: (authState) => set(state => ({ email: authState })),
  phoneNumber: "",
  setPhoneNumber: (authState) => set(state => ({ phoneNumber: authState })),
  idToken: "",
  setIdToken: (token) => set(state => ({ idToken: token }))
}));

export default AuthStore;