import React, { useEffect, useState } from "react";
import { Button, TextField, Box, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import { CircularProgress } from "@material-ui/core";
import videoPath from "./../res/landing-main.mp4";
import clsx from "clsx";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import appConfig from "../config";
import CardHeader from "@material-ui/core/CardHeader";
import * as AWS from "aws-sdk/global";
import bgImage from "../res/griDark.png";

import AuthStore from "./../stores/AuthStateStore";
import UseStore from "./../stores/ShowroomStore";
import { notifyForgotPassword } from "../server/UserAPI";

const cognito_url = appConfig.CognitoUrl;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
    position: "absolute",
    // top: '60px',
    width: "100%",
  },
  card: {
    margin: "auto",
    width: "13rem",
    height: "16rem",
    zIndex: 2,
    borderRadius: 12,
    background: "transparent",
    border: "1px solid #ffffff",
    display: "flex",
    flexDirection: "column",
  },
  cardHeader: {
    opacity: 0.8,
    background: "#e4e4e4",
    borderBottom: "1px solid white",
    textAlign: "center",
    padding: "0.9rem",
  },
  cardHeaderText: {
    fontSize: "0.8rem",
  },
  cardBody: {
    opacity: 0.7,
    background: "#c7b7ab",
    flex: 1,
    padding: "0.9rem",
  },
  loginForm: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  loginButton: {
    background: "#e4e4e4",
    width: "8rem",
    fontSize: "0.7rem",
    marginBottom: "0.5rem",
    padding: "8px",
  },
  loginInputField: {
    width: "100%",
    marginBottom: "0.9rem",
    marginTop: "0.1rem",
  },
  loginInputFieldInputAddon: {
    fontSize: "0.9rem",
  },
  loginInputFieldInput: {
    fontSize: "0.8rem",
  },
  forgotPasswordButton: {
    background: "#c7b7ab",
    opacity: 0.7,
    border: 'none',
    textDecorationLine: 'underline'
  },
  griLightMain: {
    color: theme.palette.griLightMain,
  },
  videoBackdrop: {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    background: theme.palette.griBlueMain,
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    animation: `$backdropIn 200ms ${theme.transitions.easing.easeOut}`,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  "@keyframes backdropIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  checkbox: {
    transform: 'scale(0.6)',
    padding: '0 0 9px 7px'
  },
  checkboxLabel: {
    fontSize: "12px",
    marginBottom: '3px',
    "& label": { fontSize: "0.5rem" }
  }
}));

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [renewPasswordRequired, setRenewPasswordRequired] = useState(false);
  const [verificationCodeRequired, setVerificationCodeRequired] =
    useState(false);
  const [changePasswordRequired, setChangePasswordRequired] = useState(false);
  const [isFogotPasswordRequired, setIsFogotPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState();
  const [sessionUserAttributes, setSessionUserAttributes] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const defaultEmail = process.env.REACT_APP_DEFAULT_EMAIL
  const defaultPassword =  process.env.REACT_APP_PASSWORD_FOR_DEFAULT_EMAIL
  const classes = useStyles();
  const { enqueueSnackbar } = window.snack

  const setAuthenticatedState = AuthStore(
    (state) => state.setAuthenticatedState
  );
  const startShowroom = UseStore((state) => state.actions.startShowroom);
  const setRoutingByObject = UseStore((state) => state.setRoutingByObject);
  const emailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (localStorage.checkbox && localStorage.email !== '') {
      setIsChecked(true);
      setEmail(localStorage.griShowroomAccessId);
      setPassword(Buffer.from(localStorage.griShowroomAccessKey, 'base64').toString('ascii'));
    }
  }, []);

  const handleCheckBox = (event) => {
    setIsChecked(event.target.checked)
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const newPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleClickCopy = (value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        enqueueSnackbar("copied to clipboard!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e, {
          variant: "could not copy",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setIsRequesting(false);
      });
  };

  const confirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const verificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleRenewPasswordSubmit = (e) => {
    e.preventDefault();
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      sessionUserAttributes,
      {
        onSuccess: () => {
          enqueueSnackbar("Password Has Been Successfully Changed", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setAuthenticatedState(true);
          setRoutingByObject(true);
          startShowroom();
        },
        onFailure: (err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          console.log(err);
        },
      }
    );
  };

  const handleRequestVerificationCode = (e) => {
    e.preventDefault();
    setIsRequesting(true);
    const poolData = {
      UserPoolId: appConfig.UserPoolId,
      ClientId: appConfig.ClientId,
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        enqueueSnackbar("Verification Code Has Been E-mailed", {
          variant: "info",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setVerificationCodeRequired(false);
        setChangePasswordRequired(true);
      },
      onFailure: function (err) {
        setVerificationCodeRequired(false);
        console.log(err);
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setIsReseting(true);
    const poolData = {
      UserPoolId: appConfig.UserPoolId,
      ClientId: appConfig.ClientId,
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    if (newPassword == confirmNewPassword) {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          enqueueSnackbar("Password Has Been Successfully Changed", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          setChangePasswordRequired(false);
        },
        onFailure: (err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          console.log(err);
        },
      });
    } else {
      enqueueSnackbar("‘Confirm Password’ Does Not Match The ‘New Password’", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsRequesting(true);
    notifyForgotPassword(email)
      .then(() => {
        enqueueSnackbar("Forgot password request Successful!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setIsRequesting(false);
      })
      .catch((e) => {
        console.log(e)
        enqueueSnackbar(e, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        setIsRequesting(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    var authenticationData = {
      Username: email,
      Password: password,
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );

    const poolData = {
      UserPoolId: appConfig.UserPoolId,
      ClientId: appConfig.ClientId,
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    var userData = {
      Username: email,
      Pool: userPool,
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    setCognitoUser(cognitoUser);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        if (isChecked && email !== "" && password !== "") {
          localStorage.setItem("checkbox", true);
          localStorage.setItem("griShowroomAccessId", email);
          localStorage.setItem("griShowroomAccessKey", Buffer.from(password).toString('base64'));
        } else if (!isChecked) {
          localStorage.removeItem("checkbox");
          localStorage.removeItem("griShowroomAccessId");
          localStorage.removeItem("griShowroomAccessKey");
        }
        var accessToken = result.getAccessToken().getJwtToken();
        AWS.config.region = appConfig.region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: appConfig.IdentityPoolId,
          Logins: {
            [cognito_url]: result.getIdToken().getJwtToken(),
          },
        });
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.error(error);
          } else {
            enqueueSnackbar("Successfully Logged In!", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            });
            setAuthenticatedState(true);
            setRoutingByObject(true);
            setIsSubmitting(false);
            startShowroom();
          }
        });
      },
      newPasswordRequired: function (userAttributes) {
        setIsSubmitting(false);
        delete userAttributes.email_verified;
        setSessionUserAttributes(userAttributes);
        setRenewPasswordRequired(true);
        enqueueSnackbar("Required To Renew The Password", {
          variant: "info",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
      onFailure: function (err) {
        setIsSubmitting(false);
        if (err.name == "PasswordResetRequiredException") {
          setChangePasswordRequired(true);
          enqueueSnackbar("Required To Renew The Password", {
            variant: "info",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
      },
    });
  };

  if (verificationCodeRequired) {
    return (
      <div className={classes.root}>
        <div className={classes.videoBackdrop}></div>
        <Box
          style={{ width: "100%" }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <div style={{ zIndex: 2 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{
                  variant: "body2",
                  classes: { body2: classes.cardHeaderText },
                }}
                title="RESET PASSWORD"
              />
              <CardContent className={classes.cardBody}>
                <form
                  autoComplete="off"
                  className={classes.loginForm}
                  onSubmit={handleRequestVerificationCode}
                >
                    <TextField
                      className={classes.loginInputField}
                      id="email"
                      key="email"
                      placeholder="Email"
                      onChange={emailChange}
                      type="text"
                      size="medium"
                      InputProps={{
                        classes: {
                          input: classes.loginInputFieldInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon
                              className={classes.loginInputFieldInputAddon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                    <Button type="submit" className={classes.loginButton}>
                      Submit
                      {isRequesting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </div>
        </Box>
      </div>
    );
  } else if (renewPasswordRequired || changePasswordRequired) {
    return (
      <div className={classes.root}>
        <div className={classes.videoBackdrop}></div>
        <Box
          style={{ width: "100%" }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <div style={{ zIndex: 2 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{
                  variant: "body2",
                  classes: { body2: classes.cardHeaderText },
                }}
                title="RESET PASSWORD"
              />
              <CardContent className={classes.cardBody}>
                <form
                  autoComplete="off"
                  className={classes.loginForm}
                  onSubmit={
                    renewPasswordRequired
                      ? handleRenewPasswordSubmit
                      : handleResetPassword
                  }
                >
                    {changePasswordRequired && (
                      <TextField
                        required
                        className={classes.loginInputField}
                        key="verification"
                        id="verification-code"
                        placeholder="Verification code"
                        onChange={verificationCodeChange}
                        type="text"
                        size="medium"
                        InputProps={{
                          classes: {
                            input: classes.loginInputFieldInput,
                          },
                          key: "verification",
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKeyOutlinedIcon
                                className={classes.loginInputFieldInputAddon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <TextField
                      required
                      className={classes.loginInputField}
                      id="new-password"
                      key="new-password"
                      placeholder="New password"
                      onChange={newPasswordChange}
                      type="password"
                      size="medium"
                      InputProps={{
                        classes: {
                          input: classes.loginInputFieldInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              className={classes.loginInputFieldInputAddon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      required
                      className={classes.loginInputField}
                      id="confirm-new-password"
                      key="confirm-new-password"
                      placeholder="Confirm new password"
                      onChange={confirmNewPasswordChange}
                      type="password"
                      size="medium"
                      InputProps={{
                        classes: {
                          input: classes.loginInputFieldInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              className={classes.loginInputFieldInputAddon}
                              onClick={handleClickCopy}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                    <Button type="submit" className={classes.loginButton}>
                      Submit
                      {isReseting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </div>
        </Box>
      </div>
    );
  } else if (isFogotPasswordRequired) {
    return (<div className={classes.root}>
      <div className={classes.videoBackdrop}></div>
      <Box
        style={{ width: "100%" }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <div style={{ zIndex: 2 }}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              titleTypographyProps={{
                variant: "body2",
                classes: { body2: classes.cardHeaderText },
              }}
              title="USE FOLLOWING LOGIN DETAILS"
            />
            <CardContent className={classes.cardBody}>
                <TextField
                  className={classes.loginInputField}
                  id="email"
                  value={defaultEmail}
                  key="email"
                  label="Email"
                  type="text"
                  size="medium"
                  InputProps={{
                    classes: {
                      input: classes.loginInputFieldInput,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon
                          className={classes.loginInputFieldInputAddon}
                        />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <ContentCopyIcon
                          className={classes.loginInputFieldInputAddon}
                          onClick={()=>handleClickCopy(defaultEmail)}
                        >
                        </ContentCopyIcon>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className={classes.loginInputField}
                  id="password"
                  value={defaultPassword}
                  key="password"
                  label="Password"
                  type="text"
                  size="medium"
                  InputProps={{
                    classes: {
                      input: classes.loginInputFieldInput,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                         <LockOutlinedIcon
                              className={classes.loginInputFieldInputAddon}
                            />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <ContentCopyIcon
                          className={classes.loginInputFieldInputAddon}
                          onClick={()=>handleClickCopy(defaultPassword)}
                        >
                        </ContentCopyIcon>
                      </InputAdornment>
                      ),
                  }}/>
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>)
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.videoBackdrop}></div>
        <Box
          style={{ width: "100%" }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <div style={{ zIndex: 2 }}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{
                  variant: "body2",
                  classes: { body2: classes.cardHeaderText },
                }}
                title="CUSTOMER LOGIN"
              />
              <CardContent className={classes.cardBody}>
                <form autoComplete="off" className={classes.loginForm} onSubmit={handleSubmit}>
                    <TextField
                      required
                      value={email}
                      disabled={isSubmitting}
                      className={classes.loginInputField}
                      id="login-email"
                      key="login-email"
                      placeholder="Email"
                      onChange={emailChange}
                      size="medium"
                      InputProps={{
                        classes: {
                          input: classes.loginInputFieldInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon
                              className={classes.loginInputFieldInputAddon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      required
                      disabled={isSubmitting}
                      value={password}
                      className={classes.loginInputField}
                      id="login-password"
                      placeholder="Password"
                      onChange={passwordChange}
                      type="password"
                      size="medium"
                      InputProps={{
                        classes: {
                          input: classes.loginInputFieldInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              className={classes.loginInputFieldInputAddon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} className={classes.checkbox} style={{ color: '#755c49' }} onChange={handleCheckBox} />}
                    label={<Typography className={classes.checkboxLabel}>Remember Me</Typography>}         
                  />
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                    <Button type="submit" className={classes.loginButton} disabled={isSubmitting}>
                      Login
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </Box>
                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                  >
                   <button
                      className={classes.forgotPasswordButton}
                      onClick={()=>setIsFogotPasswordRequired(true)}
                      disabled={isSubmitting}
                    >
                      <Typography type="submit" variant="subtitle2"> Forgot Login Details?</Typography>
                    </button>

                  </Box>
                </form>
              </CardContent>
            </Card>
          </div>
        </Box>
      </div>
    );
  }
}
