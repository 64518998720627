import React, { useState, useEffect, Suspense } from "react";
import { Canvas } from "@react-three/fiber";

import ShowRoomModel from "./ShowroomModel";
import TireAndSubCategoryPicker from "./TireAndSubCategoryPicker";
import TireInspector from "./TireInspector";

import * as THREE from "three";
import PostProcessing from "../components/main_screen/Postprocessing";
import SkyBox from "../components/main_screen/SkyBox";
// import { Stats } from "../res/Stats";
import UseStore from "./../stores/ShowroomStore";
import AuthStore from "./../stores//AuthStateStore";
import { getTyres, getUser } from "./../server/UserAPI";
import Pool from "./../../src/UserPool";
import { Html, PerspectiveCamera } from "@react-three/drei";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LoadSpinner from '../LoadingComponent/LoadSpinner';
import { useHistory } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";

const Loader = () => {
  return (
    <Html></Html>
  )
}

function MainScreen(props) {
  const history = useHistory();
  const setTyresAndCategories = UseStore(
    (state) => state.setTyresAndCategories
  );
  const isAuthenticated = AuthStore((state) => state.isAuthenticated);
  const setIsLoading = UseStore((state) => state.setIsLoading);
  const isLoading = UseStore((state) => state.isLoading);
  const setUserName = AuthStore((state) => state.setUserName);
  const setPhoneNumber = AuthStore((state) => state.setPhoneNumber);
  const setUserEmail = AuthStore((state) => state.setUserEmail);
  const [getUserError, setGetUserError] = useState(false);
  const idToken = AuthStore((state) => state.idToken);
  const email = AuthStore((state) => state.email);
  const selectedCategory = UseStore((state) => state.selectedCategory);
  const selectedSubCategory = UseStore((state) => state.selectedSubCategory);
  const setSelectedCategory = UseStore((state) => state.setSelectedCategory);
  const setSelectedSubCategory = UseStore(
    (state) => state.setSelectedSubCategory
  );
  const setSelectedTire = UseStore((state) => state.setSelectedTire);
  const selectedTire = UseStore((state) => state.selectedTire);
  const tyresAndCategories = UseStore((state) => state.tyresAndCategories);
  const setselectedType = UseStore((state) => state.setselectedType);
  const location = UseStore((state) => state.location);
  const setIsTireZoomed = UseStore(state => state.setIsTireZoomed);

  const [didUserClickTheCanvas, setDidUserClickTheCanvas] = useState(false);

  const { height } = useWindowDimensions();


  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [height])

  const tyreTypes = [
    { type: "Radial", id: 1 },
    { type: "Bias", id: 2 },
    { type: "Solid", id: 3 },
    { type: "Pneumatic", id: 5 },
    { type: "Test", id: 6 },
    { type: "Ultimate Air", id: 8 },
  ];

  let tyreId;
  if (selectedTire && (location === 3) && tyresAndCategories) {
    tyreId = tyreTypes.find(
      (type) =>
        type.id ==
        tyresAndCategories[selectedCategory].machineries[selectedSubCategory]
          .tyres[selectedTire].tyre_type_id
    ).id;
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated]);

  const fetchUser = () => {
    setIsLoading(true);
    if (email){
      getUserAttributes(email)
    }
    else {
      const user = Pool.getCurrentUser();
      getUserAttributes(user.username)
    }
  };

  const getUserAttributes = (username)=>{
    getUser(username, idToken)
    .then((res) => {
      let userSelectedTyresAndCategories = res.response.UserAttributes.find(
        (attribute) => attribute.Name == "custom:tire"
      );
      if (!userSelectedTyresAndCategories)
        userSelectedTyresAndCategories = null;
      else
        userSelectedTyresAndCategories = JSON.parse(
          userSelectedTyresAndCategories.Value
        );
      getTyresAndCategories(userSelectedTyresAndCategories);

      let uname = res.response.UserAttributes.find(
        (attribute) => attribute.Name == "name"
      );
      if (uname) {
        setUserName(uname.Value);
      }

      let phone_no = res.response.UserAttributes.find(
        (attribute) => attribute.Name == "phone_number"
      );
      let email = res.response.UserAttributes.find(
        (attribute) => attribute.Name == "email"
      );
      if (email) {
        setUserEmail(email.Value);
      }
      if (phone_no) {
        setPhoneNumber(phone_no.Value);
      } else {
        setPhoneNumber("");
      }
    })
    .catch((err) => {
      console.log(err)
      setIsLoading(false);
      setGetUserError(true);
    });
  };

  const mergeUserSelectedTires = (
    userSelectedTyresAndCategories,
    tyresAndCategories
  ) => {
    Object.keys(tyresAndCategories).map((categoryKey) => {
      if (
        userSelectedTyresAndCategories.CategoryIds.includes(
          tyresAndCategories[categoryKey].id
        )
      ) {
        tyresAndCategories[categoryKey].isSelected = true;
      }
      Object.keys(tyresAndCategories[categoryKey].machineries).map(
        (subCategoryKey) => {
          if (
            userSelectedTyresAndCategories.MachinaryIds.includes(
              tyresAndCategories[categoryKey].machineries[subCategoryKey].id
            )
          ) {
            tyresAndCategories[categoryKey].machineries[
              subCategoryKey
            ].isSelected = true;
            tyresAndCategories[categoryKey].isSelected = true;
          }
          Object.keys(
            tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres
          ).map((tyreKey) => {
            if (
              userSelectedTyresAndCategories.TyreIds.includes(
                tyresAndCategories[categoryKey].machineries[subCategoryKey]
                  .tyres[tyreKey].id
              )
            ) {
              tyresAndCategories[categoryKey].machineries[subCategoryKey].tyres[
                tyreKey
              ].isSelected = true;
              tyresAndCategories[categoryKey].machineries[
                subCategoryKey
              ].isSelected = true;
              tyresAndCategories[categoryKey].isSelected = true;
            }
          });
        }
      );
    });
  };

  const tyreMapToCategory = (categories, tyres) => {
    categories = categories.reduce(
      (category, { slug, ...rest }) => ((category[slug] = { key: slug, ...rest }), category),
      {}
    );
    Object.keys(categories).forEach((categoryKey) => {
      categories[categoryKey].machineries = categories[categoryKey].machineries.reduce(
        (machine, { slug, ...rest }) => ((machine[slug] = { key: slug, ...rest }), machine), {});
      Object.keys(categories[categoryKey].machineries).forEach(
        (machinaryKey) => {
          const filtered_tyre = tyres.filter(
            (tyre) =>
              tyre.purpose_id == categories[categoryKey].id &&
              tyre.machinery_id ==
              categories[categoryKey].machineries[machinaryKey].id
          );
          categories[categoryKey].machineries[machinaryKey][
            "tyres"
          ] = filtered_tyre.reduce(
            (tyre, {slug, ...rest }) => (
              (tyre[slug] = {
                ...rest,
                key: slug,
                ogmo_design: "https://viewer.dev.ogmo.xyz/?id=N5yjSDWH",
              }),
              tyre
            ),
            {}
          );
        }
      );
    });
    return categories;
  };

  const getTyresAndCategories = (userSelectedTyresAndCategories) => {
    var token = idToken || null
    getTyres(token)
      .then((response) => {
        let tyresAndCategories = tyreMapToCategory(
            response.categories,
            response.tires
        );
        if (userSelectedTyresAndCategories)
          mergeUserSelectedTires(
            userSelectedTyresAndCategories,
            tyresAndCategories
          );
        setTyresAndCategories(tyresAndCategories);
        setIsLoading(false);
      })
      .catch((error) => console.log(error.toString()));
  };

  const handleClick = (e, screen) => {
    e.preventDefault();

    let currentRoute = "/home/";

    if (screen === "category") {
      currentRoute += selectedCategory + "/" + selectedSubCategory;
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(selectedSubCategory);
      setSelectedTire(null);
      setselectedType(0);
    } else if (screen === "subcategory") {
      currentRoute += selectedCategory + "/" + selectedSubCategory;
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(selectedSubCategory);
      setSelectedTire(null);
      setselectedType(tyreId);
    } else {
      currentRoute += selectedCategory;
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(null);
      setSelectedTire(null);
    }
    setIsTireZoomed(false)
    history.push(currentRoute)
  };

  const handleClickTire = (e, screen) => {
    e.preventDefault();
    let currentRoute = "/home/";
    if (screen === "category") {
      currentRoute += selectedCategory;
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(null);
      setSelectedTire(null);
    }
    setIsTireZoomed(false)
    history.push(currentRoute);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {getUserError && (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
          maxWidth="xl"
        >
          <Card style={{ background: "salmon" }}>
            <CardContent>
              <h1>Reading User Failed</h1>
            </CardContent>
          </Card>
        </Container>
      )}
      {!getUserError && (
        <div onClick={()=> {setDidUserClickTheCanvas(true)}}>
        <Canvas
          className="canvas-container"
          style={{pointerEvents: "all"}}
          dpr={window.devicePixelRatio}
          resize={{ scroll: false, debounce: { scroll: 50, resize: 0 } }}
          onCreated={({ gl, scene }) => {
            // gl.toneMapping = THREE.CineonToneMapping;
            // gl.outputEncoding = THREE.sRGBEncoding;
          }
        }
        >
          <PerspectiveCamera makeDefault
            position={[3.75463, 6.1643, -13.63771]}
            rotation={[0, 0, 0]}
            near={0.1}
            far={10000}
            zoom={1}
          />
          <Suspense fallback={<Html fullscreen><LoadSpinner /></Html>}>
            {<ShowRoomModel didUserClickTheCanvas={didUserClickTheCanvas}/>}
            <PostProcessing />
            {/* <SkyBox/> */}
          </Suspense>
          <ambientLight intensity={1.5} />
          {/* <Stats /> */}
          <TireAndSubCategoryPicker handleClickTire={handleClickTire}/>
          <TireInspector handleClick={handleClick}/>
        </Canvas>
        </div>
      )
      }
    </>
  );
}

export default MainScreen;
