import React from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import Cardlogo from '../../res/tractorsHarvestors.png';

const useStyles = makeStyles((theme) => ({
  sliderContentText: {
    fontSize: "1.5rem",
    marginTop: "auto",
    textTransform: "uppercase",
    color: "black",
  },
  sliderItemBoxStyle: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    background: theme.palette.griLightMain,
    border: "double 5px transparent",
    borderRadius: "20px",
    backgroundImage:
      "linear-gradient(white, white),radial-gradient(circle,rgba(45, 46, 45,0.60),rgba(173, 169, 150,0.60),rgba(242, 242, 242,0.80),rgba(219, 219, 219,0.80), rgba(101, 110, 101,0.80),rgba(234, 234, 234,0.80),rgba(76, 79, 77,0.80))",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
}));

function SlideItem({ color, item, clickFunction, id }) {
  const classes = useStyles();
  // const sliderContentStyle = {
  //   width: "100%",
  //   height: "100%",
  //   // padding: "1rem",
  // };

  // const sliderContentPictureStyle = {
  //   height: "calc(100% - 2rem)",
  //   background: "white",
  //   borderRadius: "15px",
  // };

  const sliderContentPicture = {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    objectFit: "contain",
    // borderRadius: "15px",
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={clsx("swiper-slide")}
      onClick={(e) => clickFunction(id)}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
      >
        <Box
          display="flex"
          alignContent="center"
        >
          {item && (
            <img
              style={sliderContentPicture}
              src={`/${(item['image']).split(".")[0]+".png"}`}
              alt=""
            ></img>
          )}
        </Box>
        {/* <Box
          display="flex"
          flex={1}
          justifyContent="center"
          style={{ textOverflow: "ellipsis" }}
        >
          <p className={classes.sliderContentText}>
            <strong>{id}</strong>
          </p>
        </Box> */}
      </Box>
    </Box>
  );
}

export default SlideItem;
